.view-order-container {
  // background-color: #fafbfc;
  .ant-drawer-header {
    padding: 26px 0 26px 24px;

    .ant-drawer-title {
      letter-spacing: 0px;
      color: #212529;
      font-size: 22px;
      line-height: 14px;
    }
  }

  .ant-drawer-close {
    display: none;
  }

  .ant-form-item-label {
    padding: 0;
    label {
      letter-spacing: 0px;
      font-size: 14px;
      line-height: 19px;
      color: #212529;
      font-family: $font-family;
      font-weight: 500;
    }
  }

  .ant-drawer-body {
    padding: 12px 24px;
    background-color: #fafbfc;

    input,
    textarea {
      padding: 12px 0 12px 16px;
      letter-spacing: 0px;
      color: #212529e3;
      font-size: 14px;
      line-height: 19px;
      font-family: 'montserratmedium';
    }

    textarea {
      resize: none;
      height: 300px;
      line-height: 25px;
    }
  }

  .ant-input-affix-wrapper {
    padding: 0;
  }

  .ant-input-suffix {
    font-family: $font-family;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #212529;
    padding: 10px 25px;
    border-left: 1px solid #00000029;
    background-color: #f0f0f0;
  }

  .ant-tabs-nav {
    margin-bottom: 0;

    .ant-tabs-nav-list {
      width: 100%;

      .ant-tabs-tab {
        width: 50%;

        .ant-tabs-tab-btn {
          font-size: 14px;
          line-height: 19px;
          font-family: $font-family;
          font-weight: 500;
          color: #2125298f;
          text-align: center;
          width: 100%;
        }

        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: #212529;
          }
        }
      }
    }

    .ant-tabs-ink-bar {
      background: #14305c;
    }
  }

  h4.ant-typography {
    margin: 24px 0;
    font-family: $font-family;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #212529;
    text-transform: capitalize;
  }
}

.ant-drawer-footer {
  background-color: #ffffff;
  box-shadow: 0px -1px 0px #00000029;

  .user-action-button {
    text-align: right;

    .cancel-button {
      border: 1px solid #14305c;
      border-radius: 4px;
      margin-right: 18px;
      padding: 11px 30px;
      height: auto;

      span {
        letter-spacing: 0px;
        color: #14305c;
        font-size: 14px;
        line-height: 19px;
        font-weight: 600;
      }
    }
  }
}

.ant-descriptions-item-label {
  letter-spacing: 0px;
  font-size: 14px;
  line-height: 19px;
  color: #212529;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
}

.ant-descriptions-item-content {
  letter-spacing: 0px;
  color: rgba(33, 37, 41, 0.8901960784);
  font-size: 14px;
  /* line-height: 18px; */
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}
.order_products_container {
  // width: 552px;
  // height: 398px;
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  .order_products {
    font-size: 14px;
    color: black;
    font-weight: bold;
  }

  .order_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #ececec;
    padding-bottom: 20px;
    margin-top: 15px;
    .order_img_desc:first-child {
      display: block;
      gap: 10px;

      .order_desc {
        display: flex;
        flex-direction: column;
        .order_title {
          font-size: 16px;
          color: #212529de;
        }
        .order_name {
          width: 300px;
        }

        .text-width {
          width: 200px;
          margin-bottom: 0;
          font-size: 16px;
        }
        .order_reseller {
          font-size: 12px;
        }
      }
    }
    .order_quantity {
      margin-bottom: 72px;
      text-align: right;
    }
    .order_sku {
      margin-bottom: 60px;
      text-align: left;
    }
    .order_price {
      display: flex;
      align-items: flex-start;
      gap: 20px;
      margin-left: 10px;
      .order_pcs {
        font-size: 16px;
        margin-bottom: 0;
      }
      .order_total_price {
        font-size: 16px;
        font-weight: 600;
        color: #212529de;
      }
    }
  }

  .order_prices {
    margin-top: 20px;
    .order_info {
      display: flex;
      justify-content: flex-end;
      gap: 50px;
      .total_price {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}
p.order_products.price {
  text-align: right;
}
.view-order-container {
  .edit_order {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    gap: 20px;
  }
  .edit_order_btn {
    background-color: #14305c0a;
    color: #101a50;
  }
  .cancel_order_button {
    border: none;
    color: #14305c;
    font-weight: 600;
    box-shadow: none;
  }

  .user-action-order-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 25px;
  }
  .save_order_button {
    width: 102px;
    height: 36px;
    background-color: #52dc95;
    color: #101a50;
    font-weight: 600;
    border-radius: 4px;
  }
  .order_informations {
    font-size: 12px;
    font-weight: 600;
    color: black;
    margin-bottom: 0;
    background: #f0f2f5;
    padding: 4px 10px;
    border-radius: 4px;
  }
  .order_content {
    background-color: #fff;
    padding: 4px 10px;
    border-radius: 4px;
  }

  .ant-select-arrow {
    img {
      width: 10px;
    }
  }
}
.return__order-popup.ant-popconfirm {
  .ant-popover-buttons {
    display: none;
  }
  .cancel__text {
    color: #fe161d;
    font-size: 14px;
    // margin: 0;
    // svg {
    //   position: relative;
    //   top: 5px;
    // }
  }
  .approve__text {
    color: #1ea576;
    font-size: 14px;
    margin: 0;
    // svg {
    //   position: relative;
    //   top: 5px;
    // }
  }
  .ant-popover-buttons {
    text-align: center;
    padding-bottom: 16px;
    .ant-btn {
      background: #fe161d14;
      border-radius: 4px;
      letter-spacing: 0px;
      color: #fe161d;
      font-size: 14px;
      width: 90px;
      height: 35px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    .ant-btn:last-child {
      background: #52dc9514;
      border-radius: 4px;
      letter-spacing: 0px;
      color: #1ea576;
      font-size: 14px;
      width: 104px;
      height: 35px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: none;
    }
  }

  h2 {
    letter-spacing: 0px;
    color: #000000de;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 16px;
    line-height: 34px;
  }

  p {
    letter-spacing: 0px;
    color: #000000de;
    font-size: 16px;
    font-weight: normal;
    width: 324px;
    margin: 20px auto 30px auto;
  }
  .return_order_note {
    resize: none;
  }
}
.return__status {
  display: flex;
  align-items: center;
  gap: 8px;
  .corner__down-left {
    width: 30px;
    height: 30px;
    display: inline-flex;
    background: #14305c0a;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    button {
      width: 30px;
      height: 30px;
      display: inline-flex;
      background: #14305c0a;
      border-radius: 15px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: none;
    }
  }
  p {
    margin-bottom: 0;
    text-transform: capitalize;
  }

  .approved {
    letter-spacing: 0px;
    color: #1ea576;
    font-size: 14px;
    font-weight: 600;
    padding: 7px 12px;
    background: #dcf8ea;
    border-radius: 15px;
  }

  .declined {
    letter-spacing: 0px;
    color: #fe161d;
    font-size: 14px;
    font-weight: 600;
    padding: 7px 12px;
    background: #fe161d14;
    border-radius: 15px;
  }

  .requested {
    letter-spacing: 0px;
    color: #101a50;
    font-size: 14px;
    font-weight: 600;
    padding: 7px 12px;
    background: #101a5014;
    border-radius: 15px;
  }
}
.requested__table {
  letter-spacing: 0px;
  color: #101a50;
  font-size: 14px;
  font-weight: 500;
  padding: 3px 4px;
  background: #101a5014;
  border-radius: 15px;
  text-align: center;
}

.requested__minus {
  margin: 0;
}

.order_status_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: 8px 0 10px 0;
}

.order_column {
  display: flex;
  flex-direction: column;

  background: #fff;
  padding: 10px 0;
  margin-bottom: 2px;
  .order_label,
  .order_data {
    font-size: 14px;
    font-weight: 600;
    color: black;
  }
  .order_data {
    font-weight: 400;
  }
}

.product_translated_lang {
  height: 300px;
  resize: none;
  overflow-y: scroll;
}
.attribute_product_view {
  .attribute_product_view_content {
    width: 100%;
  }
}

.cancel_button {
  border: none;
  color: #14305c;
  font-weight: 600;
  box-shadow: none;
}

.save_button,
.save_pricing {
  width: 102px;
  height: 36px;
  background-color: #52dc95;
  color: #101a50;
  font-weight: 600;
  border-radius: 4px;
}

.save_pricing {
  width: 80px;
  height: 33px;
}

.order-status-table {
  display: flex;
  justify-content: flex-end;
}

.order_title {
  font-size: 16px;
  color: #212529de;
}
.order_name {
  width: 300px;
}

.text-width {
  width: 200px;
  margin-bottom: 0;
  font-size: 16px;
}
.order_reseller {
  font-size: 12px;
}

.order_informations_content {
  margin-bottom: 30px;
}

.order_view_table {
  .ant-table-thead > tr > th {
    background-color: #fff;
    font-size: 14px;
    font-weight: 700;
    padding: 8px 0;
  }
  .ant-table-tbody > tr > td {
    padding: 0;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    )::before {
    display: none;
  }
}

.order_prices {
  margin-top: 20px;
  margin-left: 70%;
  .order_info {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    .total_price,
    .net_price {
      font-size: 14px;
      font-weight: bold;
    }
    .total_price {
      text-decoration: underline;
    }
  }
}

// .split-local-seller-table {
//   table th.ant-table-cell {
//     display: flex;
//     flex-direction: row;
//     justify-content: flex-end;
//   }
// }

.merged-orders-table {
  .order_label,
  .order_data {
    font-size: 14px;
    font-weight: 600;
    color: black;
  }
  .In-progress {
    color: #75af7b;
    background-color: #75af7b14;
    width: 100px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    border: none;
  }

  .Arrived,
  .Delivered {
    color: #2a7dd1;
    background-color: #2a7dd114;
    width: 70px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    border: none;
  }

  .Delivered {
    width: 85px;
  }
  .Completed {
    color: #1ea576;
    background-color: #dcf8ea;
    width: 100px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
  }
  .Cancel {
    color: #bcbfce;
    background-color: #f3f5f7;
    width: 70px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
  }
  .Pending {
    color: #ffba00;
    background-color: #ffba0014;
    width: fit-content;
    text-transform: capitalize;
    padding: 2px 16px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    margin: 0 !important;
  }
  .green_status {
    color: #1ea576;
    background-color: #dcf8ea;
    width: fit-content;
    text-transform: capitalize;
    padding: 2px 16px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    margin: 0 !important;
  }
  .red_status {
    color: #fe161d;
    background-color: rgba(254, 22, 29, 0.0784313725);
    width: fit-content;
    text-transform: capitalize;
    padding: 2px 16px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    margin: 0 !important;
  }

  .Faild-delivery,
  .Faild-to-send,
  .Returned {
    color: #fe161d;
    background-color: #fe161d14;
    width: 115px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    border: none;
  }
  .Returned {
    width: 83px;
  }

  .Delivered-by-reseller {
    color: #856fac;
    background-color: #856fac14;
    width: 159px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
  }
}

.download-pdf {
  border: none;
  background-color: transparent;
  span {
    font-size: 14px;
    color: #101a50;
  }
}

.edit_btn {
  background: #14305c0a;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  font-weight: 600;
}
button.ant-btn.ant-btn-default.edit_btn:hover {
  background: #14305c0a;
  color: #101a50;
}
