.container-user-table {
  padding: 30px 24px 0px 24px;

  .users-container {
    display: flex;

    .flex-div {
      flex: 1;
    }

    .move-right {
      text-align: right;

      button {
        background: #14305c;
        padding: 19px 30px;
        letter-spacing: 0px;
        color: #ffffff;
        font-size: 14px;
        border: none;
        border-radius: 4px;
        line-height: 19px;
      }
    }
  }

  .user-table {
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      width: 0;
    }

    .ant-table-thead .ant-table-cell {
      letter-spacing: 0px;
      color: #252525;
      line-height: 19px;
      font-size: 14px;
      background-color: #f5f6fa;
    }

    .ant-table-tbody > tr > td {
      border-bottom: 1px solid #f5f6fa;
    }

    .ant-btn {
      background: #f5f6fa;
      border: none;
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
