.dashboard {
  padding: 16px;
  background-color: white;
  height: 100%;

  &-card {
    background-color: #fafbfc;
    height: 100%;

    &-action-get {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-flow: row;
      align-items: center;
    }
    .ant-space-vertical {
      width: 100%;
    }

    &-title,
    .ant-typography {
      padding-bottom: 0;
      margin: 0 !important;
    }
    &-p {
      font-size: 14px;
      height: 20px;
    }
    &-stats {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-divider {
      margin: 16px 0;
    }
    &-select {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .ant-picker-input > input {
        width: 80px;
        font-size: 12px;
      }
      .ant-form-item-label > label {
        font-size: 12px;
      }
      &-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background: transparent;
      }
    }
    &-translated {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: -12px;
      margin-top: 20px;
    }
  }
  &-select-form {
    .ant-form-item {
      margin-bottom: 0;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-spin {
    margin: auto;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.dashboard-card-select {
  .ant-space.ant-space-vertical {
    width: fit-content;
  }
}
