.attribute-value {
  width: 100%;
  padding: 10px;
  display: flex;
  margin: 4px 0;
  align-items: center;
  justify-content: space-between;
  background: #f4f4f4 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}

.site-tree-search-value {
  color: red;
  font-weight: 600;
  text-decoration: underline;
}

.attribute-lng-select {
  margin-bottom: 15px;
  width: 100%;

  &.ant-select-single,
  &.ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      height: auto;
      padding: 8px;

      .ant-select-selection-item,
      .ant-select-selection-placeholder,
      &::after {
        line-height: 1.3;
      }
    }
  }
}

.pagination-attribute {
  display: flex;
  justify-content: space-between;
  align-items: center;

  ul {
    li {
      margin-bottom: 0;
    }
  }
}

.attribute-filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .form-user-filters .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .form-user-filters .ant-form-item.form-search-filters {
    margin-bottom: 0;
  }

  .body-style .edit-attribute-wrapper {
    .ant-row .ant-form-item {
      margin-bottom: 20px;
    }
  }

  .attribute_btns {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }

  .export_attribute,
  #create_attribute_btn {
    font-weight: 600;
    color: #101a50;
    background-color: #e6e7ed;
    border-radius: 4px;
    height: 36px;
    width: 110px;
  }

  #create_attribute_btn {
    background-color: #52dc95;
    width: 160px;
  }
}

.attributes_table {
  .langauge_container {
    justify-content: flex-start;
  }
}

.edit-attribute-button {
  width: 100%;
}

.body-style .ant-drawer-body {
  .attribute-value-item {
    > div,
    > .ant-row.ant-form-item {
      display: flex;
      gap: 10px;
      margin-bottom: 10px;

      > .ant-form-item {
        flex-basis: 90%;
        margin-bottom: 0;
      }

      > button {
        flex-basis: 10%;
      }
    }
  }

  .ant-row {
    .edit-attribute {
      background: $dark-blue;
      display: flex;
      width: 36px;
      height: 34px;
      justify-content: center;
      align-items: center;
      padding: 0;
    }

    img {
      width: 16px;
    }
  }
}

.edit-attribute-table .ant-table-cell {
  background: #fafafa;
  border: none;
  padding: 12px 0;
}

.attribute_mapping_content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 40px;
  height: 90%;
}

.attributes_values.attribute_width {
  width: 100%;
}

.attributes_values.attribute_mapped {
  height: 100%;
  width: 459px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  #map_btn {
    background: #52dc95;
    font-weight: 600;
    color: #101a50;
    border-radius: 4px;
    height: 36px;
    width: 93px;
    cursor: pointer;
  }

  .mapped_footer {
    display: flex;
    justify-content: flex-end;
  }
}

.filterattributes {
  font-weight: bold;
  height: 36px;
}

.data__column {
  text-align: left;
  display: flex;
  gap: 12px;
  font-size: 14px;
}

.attributes_values {
  width: 341px;
  height: inherit;
  background-color: #f5f6fa;
  border-radius: 8px;
  padding: 24px;

  .input-search {
    padding: 12px 0;
  }

  .attribute__wrapper-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-center {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      border: none;
      background-color: transparent;
      font-size: 14px;
      color: #101a50;
      font-weight: 600;
      cursor: pointer;
    }
  }

  .mapped__attribute-filter {
    height: fit-content;
    overflow-y: scroll !important;
  }

  .mapped__attribute-filter::-webkit-scrollbar {
    display: none;
  }

  .attribute__form-filter .ant-table {
    height: 500px;
    overflow-y: scroll;
  }

  .ant-table-tbody > tr > td {
    background: #f5f6fa;
  }

  .attribute__form-filter .ant-table::-webkit-scrollbar {
    display: none;
  }

  &-scroll {
    height: 600px;
    overflow-y: scroll;
  }

  &-scroll::-webkit-scrollbar {
    display: none;
  }

  .attributes_values_title {
    font-size: 18px;
  }
  .ant-tree {
    background-color: #f5f6fa !important;
  }
  .attribute_mapped_list {
    width: 100%;

    .ant-table {
      background-color: #f5f6fa;

      :hover {
        background-color: #f5f6fa;
      }

      thead {
        th {
          font-size: 13px;
          padding: 5px 10px;
        }
      }

      td {
        border-bottom: 1px solid lightgray;
      }
    }
  }

  .mapping_filter {
    width: 293px;
    margin-bottom: 10px;
    border-radius: 4px;
    background-color: #f5f6fa;

    .ant-select-selector {
      background-color: #f5f6fa;
      border-radius: 4px;
      border: 1px solid #0000005e;
    }
  }

  .mapping_filter.attr_group_select {
    width: 411px;
    margin-bottom: 20px;
  }
}

.attribute_mapped .ant-table-wrapper {
  height: 600px;
  overflow-y: scroll;
}

.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  border-right: none;
}

@media only screen and (max-width: 1441px) {
  .product-filter {
    margin-bottom: 16px;
  }

  .second__filter {
    flex-wrap: wrap;
    margin-bottom: 16px;
  }
  .ant-table-wrapper {
    overflow-x: auto;
  }
}

.attribute_mapped {
  .ant-table-wrapper::-webkit-scrollbar {
    display: none;
  }

  .ant-tree.ant-tree-icon-hide.tree_bg_color {
    overflow-y: hidden;
  }

  // .ant-tree-list-holder {
  //   max-height: 638px !important;
  // }
}

.attribute_view_product {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

#map_btn {
  background: #52dc95;
  font-weight: 600;
  color: #101a50;
  border-radius: 4px;
  height: 36px;
  width: 93px;
  cursor: pointer;
}

.product-seller-attributes {
  input.ant-input {
    border: none;
    outline: none;
  }
  .ant-input-affix-wrapper {
    padding: 0px 11px;
  }

  .ant-select-selector {
    height: 30px !important;
  }
  input.ant-input {
    height: 30px !important;
  }
}
