/**
 * Poor man's vertical rhythm
 */
.chapter {
  h1, h2, h3, h4, p, ul, ol {
    margin-bottom: $vertical-rhythm-baseline;
  }
}

/**
 * Lists
 */
ul, ol {
  list-style-position: inside;
  padding-left: 3px;
}

li {
  margin-bottom: 0.5rem;
}

/**
 * Headings
 * 1. Position context for absolutely positioned anchor
 */
h1, h2, h3, h4 {
  -webkit-hyphens: auto;
  hyphens: auto;
  position: relative; /* 1 */
  text-transform: capitalize;

  /**
   * Prevent code inside headings from being capitalized
   */
  code {
    text-transform: initial;
  }
}

/**
 * Chapter titles
 */
h2 {
  font-size: 2em;
  font-weight: bold;
}

/**
 * Section headings
 */
h3 {
  color: $secondary-color;
  font-size: 1.5em;
}

/**
 * Sub-headings
 */
h4 {
  color: rgb(107, 120, 133);
  font-size: 1em;
  text-transform: uppercase;
}

/**
 * Russian and German do not capitalize every word in a header
 * See https://github.com/KittyGiraudel/sass-guidelines/issues/163
 */
:lang(de),
:lang(ru) {
  h1, h2, h3, h4 {
    text-transform: initial;
  }
}
