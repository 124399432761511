.ant-layout-sider-children {
  overflow-y: scroll;
}

.header__wrapper-sticky {
  position: sticky;
  top: 0;
  z-index: 9;
}

.change__password-btn {
  button {
    border: none;
    background: transparent;
    box-shadow: none;
    color: #52dc95;
    font-weight: 600;
    padding: 0;
    margin-bottom: 8px;

    &:hover {
      background-color: transparent;
      box-shadow: none;
    }
  }
  p {
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    margin: 6px 0 0;
  }
}

.sider-menu {
  min-height: 100vh;
  background-color: $light-white;
  box-shadow: 1px 0px 0px rgba(0, 0, 0, 0.16);

  .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: $light-white;
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $light-white;

    img {
      opacity: 0.7;
    }
  }

  .menu__pricing a {
    line-height: 17px;
  }

  .ant-menu-inline {
    .ant-menu-item::after {
      border-right-color: #52dc95;
      position: absolute;
      left: 0px;
      top: 50%;
      right: auto;
      height: 20px;
      transform: translateY(-50%);
      border-radius: 5px;
    }

    .ant-menu-submenu {
      width: calc(100% - 20px);
      margin: 0 auto;
      border-radius: 4px;
      margin: 8px 10px;
    }
  }

  .ant-menu-submenu {
    img.ant-menu-item-icon {
      opacity: 0.7;
    }

    ul.ant-menu.ant-menu-sub.ant-menu-inline {
      background-color: #000032;

      .ant-menu-title-content {
        font-size: 14px;
        line-height: 20px;
        font-family: $font-family;
        font-weight: 400;

        a {
          text-decoration: none;
          color: #1a374d;
        }
      }
    }
  }
  li.ant-menu-item {
    // display: flex;
    // gap: 14px;
    // text-decoration: none;
    span.ant-menu-title-content {
      margin-left: 15px;
      overflow: visible !important;
    }
    a,
    a:hover,
    .ant-menu-title-content {
      text-decoration: none;
      color: #1a374d;
      font-family: $font-family;
      font-weight: 400;
    }
  }
  .ant-menu-submenu-selected {
    color: #1a374d;
  }
  .ant-menu-submenu.ant-menu-submenu-inline {
    .ant-menu-submenu-title {
      display: flex;
      gap: 14px;
      &:hover {
        color: white;
      }
    }
  }

  li.ant-menu-item,
  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
    line-height: 1;
    padding: 12px 24px;
    height: 100%;
    width: calc(100% - 20px);
    margin: 0 auto;
  }

  .ant-menu-vertical > .ant-menu-submenu {
    > .ant-menu-submenu-title {
      line-height: 1;
      padding: 12px 24px;
      height: 100%;
      margin: 0;
    }
  }

  .ant-menu-light {
    .ant-menu-item:hover,
    .ant-menu-item-active,
    .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-submenu-active,
    .ant-menu-submenu-title:hover {
      background-color: #101a50;
      box-shadow: none;
    }
  }
  .ant-menu-submenu:hover > .ant-menu-submenu-title {
    > .ant-menu-submenu-expand-icon,
    > .ant-menu-submenu-arrow {
      color: #1a374d;
    }
  }

  .ant-menu-item-icon + span.ant-menu-title-content,
  .ant-menu-submenu-title .anticon + span {
    margin-left: 16px;
    font-size: 14px;
    color: #1a374d;
    font-family: $font-family;
    font-weight: 400;
    line-height: 20px;

    a,
    a:hover,
    .ant-menu-title-content {
      text-decoration: none;
      color: #1a374d;
      font-family: $font-family;
      font-weight: 400;
    }
  }

  span.ant-menu-item-icon {
    font-size: 24px;
    transition: all 0s;
  }

  &.ant-layout-sider-collapsed {
    .ant-menu-item,
    .ant-menu-submenu {
      padding: 12px;
      margin: 8px auto;
      text-align: center;
      width: calc(100% - 20px);

      span.ant-menu-item-icon {
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .ant-menu-submenu-title .anticon + span,
      .ant-menu-item-icon + .ant-menu-title-content {
        display: none;
      }

      .ant-menu-submenu-title {
        padding: 0;
        margin: 0;
        width: 100%;

        .ant-menu-item-icon {
          font-size: 24px;
        }
      }
    }

    .ant-menu-title-content,
    .ant-menu.ant-menu-inline-collapsed
      > .ant-menu-submenu
      > .ant-menu-submenu-title
      .ant-menu-item-icon
      + span {
      display: none;
    }
  }

  div.logo,
  div.icon {
    padding: 16px;
    float: left;

    img.logo {
      width: 100%;
      height: 46px;
    }

    img.icon {
      width: 100%;
    }

    .trigger {
      position: absolute;
      right: -15px;
      top: 7px;
      border-radius: 50%;
      box-shadow: 2px 0px 4px rgba(155, 110, 200, 0.31);
      background-color: $dark-blue;
      width: 30px;
      height: 30px;
      border: none;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 12px;
      }
    }
  }

  div.icon {
    padding: 24px;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #101a50;
    border-radius: 4px;
    margin: 8px 10px;

    span.ant-menu-title-content a {
      font-family: $font-family;
      font-weight: bold;
    }
  }
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light,
.sider-menu .ant-menu-submenu ul.ant-menu.ant-menu-sub.ant-menu-inline {
  background: #000032;
}

.ant-layout-sider-children {
  background: #000032;
}

.sider-menu .ant-menu-submenu ul.ant-menu.ant-menu-sub.ant-menu-inline .ant-menu-title-content a {
  color: white;
}

.sider-menu li.ant-menu-item .ant-menu-title-content {
  color: white;
  a {
    color: white;
  }
}
.sider-menu {
  .ant-menu-submenu-title {
    color: white;
  }
  .ant-menu-submenu-inline {
    .ant-menu {
      .ant-menu-title-content {
        margin-left: 6px;
      }
    }
  }
}

.ant-menu-submenu-inline .ant-menu-submenu-arrow::after {
  color: white;
}
.ant-menu-submenu-inline .ant-menu-submenu-arrow::before {
  color: white;
}

.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  background: #000032;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
  border-right: none;
}

.sider-menu .ant-menu-light .ant-menu-item:hover {
  border-radius: 6px;
}

.service__sidebar a:hover {
  color: #ffffff !important;
}
