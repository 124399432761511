.body-style .ant-drawer-body {
  .lan-input {
    border-radius: 4px;
    border: 1px solid #00000029;
    background-color: #ffffff;
    font-size: 14px;
    height: 46px;

    &::placeholder {
      letter-spacing: 0px;
      color: #2125295e;
      font-size: 14px;
    }
  }
}
textarea#short_description {
  resize: none;
}

.title-attributes {
  font-weight: bold;
  font-size: 16px;
  color: #212529;
}

.values {
  margin-top: 30px;
}
.attribute_languages {
  display: flex;
  gap: 30px;
  background: #f5f6fa;
  padding: 10px;
}
.ant-form-item {
  margin-bottom: 10px;
}
.ant-input#added-attribute {
  width: 239px;
  margin-right: 25px;
}
.add_field_btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
