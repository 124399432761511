.register-page {
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: #52dc95;
    border-color: #52dc95;
    .ant-steps-icon {
      color: black;
    }
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #52dc95;
    border-color: #52dc95;
    .ant-steps-icon {
      color: black;
    }
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
  .register-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    background-color: white;
    width: 100%;
    z-index: 2;
    padding: 0 20px;
    height: 60px;
    box-shadow: 0px 4px 8px #0000000f;
    .register-logo {
      height: 46px;
      margin-top: 5px;
    }
    .register-login {
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      p {
        margin-bottom: 0;
      }
      a {
        text-decoration: none;
        color: #1859ff;
        font-weight: bold;
      }
    }
  }
  .register-container {
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 992px) {
      flex-direction: column;
    }
  }
  .register-form {
    margin-top: 20px;
    padding: 59px 110px 0 110px;
    @media only screen and (max-width: 992px) {
      padding: 55px 20px 0 20px;
      height: 100%;
    }
    @media screen and (min-width: 993px) and (max-width: 1540px) {
      padding: 55px 20px 0 20px;
    }
    h2 {
      text-transform: none;
      font-size: 24px;
      color: black;
      @media only screen and (max-width: 992px) {
        font-size: 20px;
      }
    }
    p {
      font-size: 16px;
      color: #000000ab;
      @media only screen and (max-width: 992px) {
        font-size: 14px;
      }
    }
  }
}

.register-steps {
  input {
    height: 40px;
    border-radius: 4px;
  }
  .ant-form-item-control-input-content {
    .ant-input-affix-wrapper.ant-input-password {
      border-radius: 4px;
      padding: 0 11px;
      .ant-input {
        height: 40px;
        border-radius: 4px;
      }
    }
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-form-item-label {
    font-weight: 600;
  }
  .ant-picker {
    border-radius: 4px;
    .ant-picker-input {
      display: flex;
      flex-direction: row-reverse;
      width: 200px;
      gap: 10px;

      .ant-picker-suffix {
        color: black;
        font-size: 16px;
      }
      height: 35px;
    }
  }
  .register-password,
  .register-city {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
    .half-width {
      width: 50%;
    }
  }
  .register-checkbox {
    color: black;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
    .register-span {
      text-decoration: underline;
    }
  }
  .create-button {
    display: flex;
    justify-content: flex-end;
    .continue-button {
      height: 46px;
      width: 203px;
      background-color: #14305c;
      color: white;
      border-radius: 4px;
      cursor: pointer;
      font-size: 16px;
      @media only screen and (max-width: 992px) {
        height: 35px;
        font-size: 14px;
      }
    }
  }
  p.informations {
    font-size: 12px;
    color: black;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 13px;
  }
  .browse-blue {
    color: #2c58ff;
    font-weight: 600;
  }
  .city-width {
    width: 70%;
  }
  .another-half-width {
    width: 30%;
  }
  span.text {
    font-size: 11px;
  }
  .footer-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    .register-skip {
      font-weight: bold;
      color: #101a50;
      font-size: 16px;
      border: none;
      @media only screen and (max-width: 992px) {
        font-size: 14px;
      }
    }
  }
  .select-phone {
    background-color: #ececec;
    border: 1px solid #ececec;
  }
  .ant-input-group-addon {
    background-color: #ececec;
  }
  .user-details {
    display: flex;
    flex-direction: row;
    gap: 50px;
    border-bottom: 1px solid #b4b4b4;
    .right-side {
      width: 200px;
    }
    span,
    .informations-title {
      font-size: 14px;
      color: #0000008f;
    }
    .informations-title {
      color: black;
      font-weight: bold;
    }
  }
  .user-details.bordered {
    border-bottom: none;
  }
}
#hidingScrollBar {
  overflow: hidden;
  width: 50%;
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
}

@media screen and (max-width: 1880px), screen and (max-height: 768px) {
  #hidingScrollBar {
    overflow: hidden;
  }

  .register-form {
    width: 100%;
    height: 760px;
    overflow-y: scroll;

    @media only screen and (max-width: 1900px) {
      .ant-steps-item-title {
        padding-right: 44px;
        white-space: pre-wrap;
      }

      .ant-steps-item,
      .ant-steps-item-wait {
        padding-left: 34px;
      }
    }
  }

  .register-form::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}

.register-alert-error {
  padding-top: 25px;
}

.register-photo {
  height: 100vh;
  background-position: bottom;
  background-size: cover;
  background-image: url('/assets/images/register-matric.png');
  width: 50%;
  @media only screen and (max-width: 992px) {
    display: none;
  }
}

.aplication_title {
  font-size: 24px;
  color: black;
  font-weight: 600;
}

.application_text {
  font-size: 16px;
  color: #000000ab;
}

.application-content {
  padding: 150px;
  @media (max-width: 780px) {
    padding: 70px 20px 0px 20px;
  }
}

.applicaion-link {
  color: #1ea576;
  text-decoration: none;
  font-weight: 600;
}
