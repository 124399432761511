.simple-checkbox {
  margin-bottom: 12px;

  .simple-text {
    margin-left: 4px;
    color: black;
    font-size: 14px;
  }
}

.images-edit-seller {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;

  .cover-active-image {
    background-color: #f0f3f5;
    text-align: center;
    cursor: pointer;
  }

  .image-wrapper {
    position: relative;

    .ant-image {
      background-color: #f0f3f5;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .cover-image {
      display: none;
    }

    .x-button {
      position: absolute;
      right: 6px;
      font-size: 20px;
      z-index: 10;
      top: 0;
      cursor: pointer;
    }
  }
}

.image-wrapper:hover {
  transition: all 1s ease-out;

  .cover-image {
    transition: all 1s ease-out;
    display: block;
    background-color: #f0f3f5;
    text-align: center;
    cursor: pointer;
    color: #000000;
    background: #f0f5ff;
    border-color: #adc6ff;
    padding: 6px 10px;
    border-radius: 8px;
  }
}

.body-style {
  .edit-wrapper {
    .clock_icon {
      padding: 10px 10px 8px 10px;
      border-radius: 4px;
      background-color: #f0f3f5;
      cursor: pointer;
    }

    .advance-edit-button {
      font-size: 14px;
      border: none;
      text-decoration: none;
      color: #2c58ff;
      font-weight: normal;
      background: none;

      &.attributes-edit {
        border-radius: 4px;
        background-color: $dark-blue;
        padding: 4px 30px;
        border-color: $dark-blue;
        color: $light-white;
      }
    }

    .align-icons {
      text-align: right;
      display: flex;

      .edit-button {
        margin-left: 16px;
        background: $main-color;
        padding: 6px 7px 11px 8px;
        border-radius: 4px;
        height: min-content;
      }
    }
  }

  // .ant-drawer-body {
  //   padding: 20px 24px;
  //   background: #fafbfc;

  //   .edit-wrapper {
  //     margin-bottom: 20px;
  //   }

  //   .edit-button {
  //     background: $main-color;
  //     padding: 6px 7px 11px 8px;
  //     border-radius: 4px;
  //     display: inline-block;
  //     height: 36px;
  //   }

  //   .ant-row {
  //     .after-edit-hide {
  //       height: 22px;
  //     }

  //     .input-disabled {
  //       border: none;
  //       background: transparent;
  //     }

  //     textarea {
  //       color: black;
  //     }

  //     .edit-button {
  //       background: #f0f3f5;
  //       border-radius: 4px;
  //       height: 41px;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       text-decoration: none;
  //       padding: 0 16px;
  //     }

  //     .ant-select-selector {
  //       border-radius: 5px;
  //       height: 32px;
  //       // border: none;
  //       background-color: #fff;

  //       .ant-select-selection-placeholder {
  //         font-family: $font-family;
  //         font-weight: 500;
  //         font-size: 14px;
  //         line-height: 32px;
  //       }

  //       .ant-select-selection-item {
  //         font-size: 14px;
  //         color: #212529e3;
  //         font-size: 14px;
  //         color: #262626;
  //         // font-weight: bold;
  //       }
  //     }

  //     img {
  //       width: 10px;
  //     }

  //     .ant-select-disabled {
  //       .ant-select-selector {
  //         background: transparent;
  //         border: none;
  //         padding: 0px 10px;
  //       }

  //       .ant-select-arrow {
  //         display: none;
  //       }
  //     }

  //     .permissions-wrapper .ant-tree-select .ant-select-selector {
  //       padding: 0 0 0 5px;
  //     }

  //     .ant-form-item {
  //       margin-bottom: 8px;

  //       .ant-form-item-label {
  //         padding: 0;

  //         label {
  //           letter-spacing: 0px;
  //           font-size: 14px;
  //           line-height: 19px;
  //           color: #212529;
  //           font-family: $font-family;
  //           font-weight: 700;
  //         }
  //       }
  //     }
  //   }

  //   .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  //     padding-left: 0;
  //   }

  //   input {
  //     padding: 6px 0;
  //     letter-spacing: 0px;
  //     color: #212529e3;
  //     font-size: 14px;
  //     line-height: 18px;
  //     font-family: $font-family;
  //     font-weight: 500;

  //     &::placeholder {
  //       letter-spacing: 0px;
  //       color: #2125295e;
  //       font-size: 14px;
  //     }
  //   }
  // }

  #long_description,
  #short_description {
    resize: none;
  }

  .ant-drawer-header {
    padding: 16px 24px;

    .ant-drawer-title {
      letter-spacing: 0px;
      color: #212529;
    }
  }

  .ant-drawer-footer {
    background-color: #ffffff;
    box-shadow: 0px -1px 0px #00000029;

    .user-action-button {
      text-align: right;

      .cancel-button {
        border: 1px solid $main-color;
        border-radius: 4px;
        margin-right: 18px;
        padding: 4px 30px;
        height: auto;

        span {
          letter-spacing: 0px;
          color: #14305c;
          font-size: 14px;
          line-height: 19px;
          font-weight: 600;
        }
      }

      .save-button {
        height: auto;
        border-radius: 4px;
        background-color: #14305c;
        padding: 4px 30px;
        border-color: #14305c;

        span {
          letter-spacing: 0px;
          color: #ffffff;
          font-size: 14px;
          line-height: 19px;
          font-weight: 600;
        }
      }
    }
  }
}

.language_translate_wrapper {
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;

  .language_edit {
    .language_span {
      color: #000000;
      font-weight: 600;
      font-size: 14px;
      margin-right: 9px;
    }
  }

  .language_tag {
    cursor: pointer;
  }
}

table {
  .ant-tag-success {
    font-size: 13px;
    cursor: auto;
    border: 1px solid #52dc95;
    border-radius: 4px;
    background: rgba(82, 220, 149, 0.1215686275);
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 32px;
    height: 24px;
    color: #52dc95;
    text-decoration: none;
  }

  .ant-tag-success:last-child {
    margin-right: 0;
  }
}

.clock_icon_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.edit-variant-button {
  background: #14305c;
  border-radius: 4px;
  height: 36px;
  display: flex;
  justify-content: center;
}

.assign-select {
  display: flex;
  margin-top: 15px;
  gap: 10px;

  .ant-form-item-control-input {
    width: 165px;
  }

  .ant-form-item-control-input-content {
    .ant-select.ant-select-in-form-item {
      .ant-select-selector {
        padding: 0px 10px 0px 10px;
      }
    }
  }
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item {
  letter-spacing: 0px;
  font-size: 14px;
  line-height: 19px;
  color: #212529;
  font-family: $font-family;
  font-weight: bold;
}

.table-title-curd {
  display: flex;
  gap: 24px;
  margin-left: 10px;
}

.user__footer-drawer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  .form-cancel {
    color: #1439c6;
    border: none;
    background: transparent;
    font-size: 16px;
  }

  .form-save {
    color: white;
    background: #1439c6;
    border: none;
    padding: 5px 14px;
    border-radius: 8px;
    font-size: 16px;
  }
}

.ant-drawer-header-title {
  flex-direction: row-reverse;
}

.employ-role-drawer {
  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }

  .ant-drawer-footer {
    border: none;
    box-shadow: unset;
  }

  .ant-table-cell {
    background-color: #fafafa;
  }

  input,
  textarea {
    border-radius: 6px;
    background-color: #fafafa;
  }
}

.user__drawer-role {
  display: flex;

  p {
    margin-bottom: 0;
  }
}

.input__user-role {
  margin-bottom: 12px;
}

.users_filter {
  display: flex;
  justify-content: space-between;
}

.ant-modal-mask,
.ant-drawer-mask {
  background-color: transparent;
  background: transparent;
}

button.ant-btn.ant-btn-default.add_user_btn {
  background-color: #52dc95;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #101a50;
  font-size: 14px;
  // line-height: 19px;
  padding: 8px 10px;
  height: 100%;
  border-radius: 4px;
  border: none;
  margin-left: 12px;
  font-weight: 500;

  // &:hover {
  //   background-color: #d4dadd;
  //   color: #101a50;
  //   text-decoration: none;
  //   transition: all 0.3s;
  // }

  svg {
    position: relative;
    top: 2px;
  }
}

.permissions-edit-role-btn {
  display: flex;
  gap: 20px;

  button.edit_role_btn {
    background-color: #52dc95;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #101a50;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
  }
}

.icon__gap {
  margin: 0 8px;
}

.checkbox__gap {
  &:first-child {
    margin-left: 6px !important;
  }

  gap: 10px !important;
  margin-left: 12px !important;
}

.title_heading {
  gap: 12px !important;
}

.warpper_crud-checkbox {
  gap: 21px !important;
}

.text__crud-checkbox {
  gap: 6.7px !important;
  margin-left: 9px !important;
}

.warpper_crud {
  gap: 18px !important;
}

.text__crud {
  gap: 14.2px !important;
}

//viewproduct

::-webkit-scrollbar {
  height: 4px;
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: #c9c9c9;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #ececec;
}

.view_product {
  .ant-drawer-body {
    background-color: #fafbfc;
    overflow: unset;
    height: 100%;
  }
}

.view-product-translate {
  display: flex;
  flex-direction: column;
  width: 30%;
  overflow-y: scroll;
  padding-right: 2px;
  padding-left: 2px;
  height: 100%;
  overflow-x: hidden;

  .product-images {
    display: inline-flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 2px;
    height: 100%;
    margin-bottom: 24px;

    img {
      width: 100px;
      height: 100px;
      object-fit: contain;
    }

    .is_cover-image:first-child {
      display: block;
      width: 100%;

      img {
        height: 300px;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .view-product-form {
    p {
      letter-spacing: 0px;
      color: #000000;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
    }

    .assign-user {
      .ant-form-item-label {
        label {
          letter-spacing: 0px;
          color: #000000;
          font-size: 14px;
          font-weight: 600;
        }
      }

      .ant-select-selector {
        background: white;
        border-radius: 4px;
        letter-spacing: 0px;
        color: #262626;
        opacity: 1;

        .ant-select-selection-placeholder,
        .ant-select-selection-item {
          font-size: 14px;
          font-weight: 600;
          color: #262626;
        }
      }
    }

    input,
    textarea,
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      letter-spacing: 0px;
      color: #000000;
      border: none;
      background-color: #fafbfc;
    }
  }
}

.product-view-wrapper {
  height: 100%;
  display: flex;
  overflow: hidden;

  .product-info-edit {
    width: 45%;
    padding: 0 9px;
  }

  .first-form {
    height: 100%;
    overflow-y: scroll;
    padding-right: 10px;
  }

  .product-translated-info {
    width: 40%;
  }

  .translate-header {
    display: flex;
    flex-flow: column;
    margin-bottom: 10px;

    & > * {
      width: 100%;
    }

    .ant-form-item-control-input-content {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      margin-left: 14px;
      margin-right: 14px;
    }

    .language-title {
      display: flex;
      font-weight: 600;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  .long_description_title {
    font-size: 14px;
  }
}

.shopware_view_attributes {
  margin-top: 60px;
}

.selected_padding {
  .ant-select-selector {
    padding-left: 0 !important;
  }

  // .ant-select-selector:last-child {
  //   padding-left: 11px !important;
  // }
}

.status_selected_product_view {
  label {
    padding-left: 11px;
  }

  .ant-select-selector {
    padding-left: 0 !important;
  }

  .ant-form-item-control-input-content {
    padding-left: 11px;
  }
}

.changed_bg {
  background-color: #fff;
}

.edit-local-seller-auth {
  .ant-input-disabled {
    color: black !important;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: black;
    background-color: #fff;
  }

  .ant-picker.ant-picker-large.ant-picker-disabled {
    background-color: #fff !important;
    color: black !important;
    // input {
    //   color: black !important;
    // }
  }

  .seller-auth-datepicker input {
    color: black !important;
  }

  .ant-select-arrow {
    display: none;
  }

  .ant-picker-suffix {
    display: none;
  }

  // .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after {
  //   display: none !important;
  // }
}

// .background__between-rows {

// }
.background__between-rows > .ant-row:nth-child(2n + 1) {
  background: #fff;

  .ant-input-disabled {
    background-color: transparent !important;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-select-selector {
    background-color: transparent !important;
  }
}

.background__between-rows {
  padding-right: 10px;

  .ant-row {
    display: flex;
    align-items: center;
  }

  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
}

.seller-information-section {
  display: flex;
  flex-direction: column;

  .seller-information-data {
    display: flex;
    flex-direction: initial;
    width: 100%;

    .ant-select.mapping_filter.attr_group_select.ant-select-single.ant-select-show-arrow.ant-select-show-search {
      width: 100%;
      span.ant-select-arrow {
        top: 39%;
      }
    }
    .mapping_filter {
      padding-bottom: 10px;
    }

    .ant-card.ant-card-bordered {
      margin: 10px;
    }
  }

  .select-information-seller {
    margin-bottom: 20px;

    .ant-select-selector {
      width: 400px;
    }
  }

  table .ant-table-cell:last-child {
    text-align: left;
  }
}

.new__view-product {
  .ant-drawer-body {
    padding: 0;
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none !important;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: pointer;
}

.new__product-view-wrapper {
  padding: 10px;
  overflow-y: auto;
  gap: 10px;

  .description_wrapper {
    height: 400px;
  }

  .form_container {
    padding: 10px 10px 0 10px;
    background-color: white;
  }

  .view-product-translate {
    overflow: unset;
  }

  .info__wrapper {
    flex: 1.5;
  }

  .translate__wrapper {
    flex: 1;
  }

  .ant-table-thead > tr > th {
    background-color: transparent;
    letter-spacing: 0px;
    color: #212529;
    font-weight: 600;
  }

  .not-in-shop {
    color: #878eaa;
    border: 1px solid #d6d9e5;
    background: #f5f6fa;
    font-size: 12px;
    font-weight: 600;
    padding: 3px 0px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    gap: 4px;
    width: 110px;
  }

  .in-shop {
    color: #52dc95;
    border: 1px solid #52dc95;
    background: rgba(82, 220, 149, 0.1215686275);
    font-size: 12px;
    font-weight: 600;
    padding: 3px 0px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    gap: 4px;
    width: 110px;
  }

  .not-synced {
    border: 1px solid #ff9a9d;
    border-radius: 4px;
    background: #fe161d1f;
    letter-spacing: 0px;
    color: #f76a6e;
    font-size: 12px;
    font-weight: 600;
    padding: 3px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 110px;
  }

  .assigne__wrapper {
    display: flex;
    margin-bottom: 24px;
    gap: 34px;
    padding-top: 5px;

    .content {
      display: flex;
      gap: 12px;
      p {
        letter-spacing: 0px;
        color: #000000;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0;
      }

      div {
        letter-spacing: 0px;
        color: #262626;
        letter-spacing: 0px;
        color: #000000;
        font-size: 14px;
      }
    }
  }

  .assigne__wrapper_local {
    display: flex;
    margin-bottom: 24px;
    justify-content: space-between;

    .content {
      display: flex;
      gap: 12px;
      p {
        letter-spacing: 0px;
        color: #000000;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0;
      }

      div {
        letter-spacing: 0px;
        color: #262626;
        letter-spacing: 0px;
        color: #000000;
        font-size: 14px;
      }
    }
  }

  .last__updated {
    letter-spacing: 0px;
    color: #212529;
    font-size: 14px;
    font-weight: normal;
  }

  .images-wrapper {
    margin-bottom: 24px;
  }
  .is_cover-image {
    position: relative;

    .ant-image:first-child {
      width: 100px;
      height: 100px;
    }

    .ant-image {
      width: 100px;
      height: 100px;

      img {
        height: 100%;
        object-fit: contain;
      }
    }

    .cover-image {
      position: absolute;
      top: 3px;
      left: 3px;
      color: #000000;
      background: #f0f5ff;
      border-color: #adc6ff;
      padding: 1px 4px;
      border-radius: 8px;
      font-size: 12px;
    }
  }

  .variant__view-color {
    background: #ffffff;
    padding: 10px;
  }

  .product__info {
    display: flex;
    gap: 3px;
    margin: 24px 0;

    .data__wrapper {
      flex: 1;
    }
  }

  .data__wrapper-concode {
    margin-top: 12px;
  }

  .description__wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    )::before {
    display: none;
  }

  .ant-form-item-label > label,
  .ant-collapse,
  .long_description_title {
    font-weight: 500;
    color: #212529;
  }

  .data__wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .value {
      letter-spacing: 0px;
      color: #212529;
      font-size: 11.5px;
      font-weight: normal;
      margin-bottom: 0;
      padding-left: 8px;
    }
    .info-title {
      letter-spacing: 0px;
      color: #212529;
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 0;
      padding: 8px;
      background: #f0f2f5;
      border-radius: 4px;
    }

    input {
      border: 1px solid #00000029;
      border-radius: 4px;
      background: #f5f5f5;
    }
  }
}

.shopware_view_attributes-new {
  background-color: white;
  padding: 10px;
  margin-top: 10px;
  .ant-collapse {
    background-color: transparent;
  }

  .ant-collapse-content-box {
    height: 260px;
    overflow-y: auto;
    background: transparent;
    padding: 0;
  }

  .category__wrapper .ant-tree-list-holder,
  .category__wrapper .ant-collapse-content-box {
    background-color: transparent;
  }
  .category-tree {
    height: auto;
    margin-bottom: 20px;
  }

  .attributes_values {
    background-color: white;
    border-radius: 4px;
    padding: 0;
  }

  .attribute_product_view {
    margin-bottom: 20px;
  }

  .check-red {
    .ant-select-multiple .ant-select-selection-item {
      background: 'red';
    }
  }
  .check-green {
    .ant-select-multiple .ant-select-selection-item {
      background: 'green';
    }
  }
}

.flex__images {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.form__wrapper-variant {
  height: 100%;
}

.read__variant {
  .ant-row {
    width: 135px;
    min-width: 135px;
  }
  input {
    border: none;
    font-size: 12px;
    width: 135px;
    background-color: transparent;
    background: transparent;
    padding: 0;
    outline: none;
  }

  label {
    font-size: 14px;
  }
}
.read__variant:hover {
  border-color: transparent;
}

.edit__variant-ean {
  .ant-row {
    width: 136px;
  }
  input {
    border: 1px solid #00000029;
    border-radius: 4px;
    font-size: 12px;
    width: 136px;
  }
  label {
    font-size: 14px;
  }
}

.edit__variant-cm {
  .ant-row {
    width: 80px;
  }
  input {
    border: 1px solid #00000029;
    border-radius: 4px;
    width: 80px;
  }
  label {
    font-size: 14px;
  }
}
.read__variant-last {
  .ant-row {
    width: 60px;
  }
  input {
    border: none;
    width: 60px;
  }
  label {
    font-size: 14px;
  }
}
.card-variants {
  .ant-card-body {
    padding: 0;
  }
}

.card-mapped-attrib {
  .mapped__attributes {
    height: fit-content;
  }
}
