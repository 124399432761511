.ant-popconfirm {
  text-align: center;
  width: 100vw;
  left: 0 !important;
  top: 0 !important;
  position: fixed;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.42);
  transform-origin: unset !important;

  .ant-popover-content {
    width: 525px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .ant-popover-message {
    padding: 0;
  }

  .ant-popover-message-title {
    padding: 0;
    width: 80%;
    margin: 0 auto;
  }

  .ant-popover-inner-content {
    padding: 19px 26px;
    width: 100%;
  }

  .title {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 28px;
    font-family: $font-family;
    font-weight: 500;
  }

  .text {
    font-size: 14px;
    line-height: 27px;
    font-family: $font-family;
    margin-bottom: 33px;
  }

  .anticon-exclamation-circle,
  .ant-popover-arrow-content {
    display: none;
  }

  .ant-popover-buttons {
    margin-bottom: 0;

    .ant-btn {
      font-size: 14px;
      line-height: 27px;
      font-family: $font-family;
      font-weight: 500;
      padding: 11px;
      height: 100%;
      border-radius: 5px;
      background-color: #f0f2f5;
      border-color: #f0f2f5;
      margin: 0 16px 0px 0;
      width: calc(50% - 8px);

      &.ant-btn-primary {
        background-color: $main-color;
        border-color: $main-color;
        margin-right: 0;
      }
    }
  }
}
