.newclass {
  transform: translateX(-59%) !important;
}

.search-option-wrapper {
  display: flex;
  align-items: center;
}

.body-style {
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 100%;
  }
  .ant-input-group-addon {
    border: 1px solid #d6d6d6;
    border-radius: 0px 4px 4px 0px;
    background: #f0f0f0;
    padding: 0 24px;
    font-weight: bold;
    font-family: $font-family;
    font-weight: 700;
  }
}

.option-content {
  display: flex;
  .option-img {
    width: 100px;
    height: 70px;
  }

  .heading-title {
    padding-left: 16px;
    h4 {
      color: #212529;
      font-weight: bold;
      font-family: $font-family;
      font-weight: 600;
      font-size: 14px;
    }
    p {
      color: #b5b5b5;
      font-weight: 300;
      font-size: 14px;
      font-family: $font-family;
      font-weight: 500;
      white-space: pre-wrap;
    }
  }
}

.wrapper-second-drawer {
  &-width {
    width: 80%;
  }

  @media screen and (min-width: 1200px) and (max-width: 1441px) {
    .ant-drawer-content-wrapper {
      width: 95% !important;
    }
  }
  .ant-form-item {
    margin-bottom: 10px;
  }

  .ant-drawer-body {
    background: #fafbfc;
  }
  .anticon-close svg {
    color: #212529;
    width: 18px;
    height: 18px;
  }
  .langague-double-arrow {
    display: flex;
    align-items: center;
    padding: 0 15px;
    .double-arrow-btn {
      padding: 0;
      border: none;
      background: #fafbfc;
    }
    img {
      width: 21px;
      height: 24px;
    }
  }

  .translate-header {
    display: flex;
    flex-flow: column;
    margin-bottom: 10px;

    & > * {
      width: 100%;
    }

    .ant-form-item-control-input-content {
      display: flex;
      gap: 20px;
    }

    .language-title {
      display: flex;
      font-weight: 600;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .ant-select.ant-select-in-form-item {
      width: 305px;
    }
  }
  .long_description_title {
    font-size: 14px;
  }

  .header_desc {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-bottom: 14px;
    margin-top: 9px;
    font-size: 14px;
  }
  .advanced-translate-forms {
    display: flex;
    .product_image_translate {
      padding: 30px 20px 0 0;
      .product_image {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      }
      .images-wrapper {
        display: flex;
        gap: 10px;

        .extra-images {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
          overflow: auto;

          &::-webkit-scrollbar {
            width: 3px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
          }

          .ant-image {
            height: 100px;
            width: 100px;

            .ant-image-img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }

      .images-wrapper {
        img {
          border: 1px solid #d6d9e5;
          border-radius: 4px;
        }
      }
      .product_title,
      .product_info {
        color: #000000;
        font-weight: 600;
        font-size: 14px;
      }
      .product_info {
        font-weight: 400;
      }
      .product_content {
        margin-top: 20px;
      }
      .product_column {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .product_text {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
      }

      .assigned_user {
        display: flex;
        align-items: center;
      }
    }
    .cancel_translate_btn {
      border: none;
      font-size: 16px;
      font-weight: 600;
      color: #14305c;
    }

    .ant-form-item-label {
      padding: 0;
    }
    label {
      color: #212529;
      font-size: 14px;
      font-family: $font-family;
      font-weight: 500;
    }

    > div {
      flex: 1;

      &.first-form {
        // padding-right: 24px;
        #long_description {
          &.input-disabled {
            .ql-toolbar {
              display: block;
              opacity: 0;
            }

            span,
            button {
              cursor: default;
            }
          }
        }
      }

      .form_container {
        background-color: #fff;
        padding: 0 10px 10px 10px;
      }

      .second_container {
        padding-top: 6px;
      }

      &.second-form {
        padding-left: 24px;
        // border-left: 2px solid #00000029;
      }

      .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background: transparent;
        border: none;
        padding: 0px 10px;
      }

      .ant-form-item-label {
        margin-top: 8px;
        margin-bottom: 8px;
      }

      input {
        border: 1px solid #00000029;
        border-radius: 4px;
        color: #212529;
        font-size: 14px;
        font-family: $font-family;
        font-weight: 500;
        height: 32px;

        &.input-disabled {
          border: none;
          background: transparent;
        }
      }

      .ant-form-item-required {
        height: 10px;
      }
    }
  }

  .ant-drawer-close {
    display: none;
  }

  .ant-drawer-footer {
    box-shadow: 0px -1px 0px #00000029;
    padding: 4px 16px;
  }

  .go-back-btn {
    display: flex;
    align-items: center;
    padding: 0;
  }

  .right-purp-arrow {
    margin-top: 2px;
    margin-right: 7px;
  }
  .user-action-button {
    text-align: right;

    .cancel-button {
      border: 1px solid #14305c;
      border-radius: 4px;
      height: auto;
      letter-spacing: 0px;
      color: #14305c;
      font-size: 14px;
      line-height: 18px;
      padding: 6px 30px;
      font-weight: 600;
    }

    .save-button {
      margin-left: 16px;
      height: auto;
      border-radius: 4px;
      background-color: #14305c;
      line-height: 18px;
      padding: 7px 30px;
      border-color: #14305c;
      letter-spacing: 0px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 600;
      border: none;
    }
  }

  .wrapper-title-drawer {
    display: flex;
    align-items: center;

    h2 {
      color: #212529;
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 0;
      margin-left: 24px;
      font-family: $font-family;
      font-weight: 700;
    }

    button {
      color: #14305c;
      font-weight: 600;
      font-size: 14px;
      border: none;
      background: none;
      box-shadow: none;
    }
  }
}

.shopware-button {
  .ant-btn-primary {
    height: auto;
    border-radius: 4px;
    background-color: $main-color;
    padding: 11px 30px;
    border-color: $main-color;
    letter-spacing: 0px;
    color: #ffffff;
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
  }
}

#long_description {
  &.input-disabled {
    background-color: transparent;
    border: none;

    .ql-toolbar {
      display: none;
    }

    .ql-container.ql-snow {
      border: none;
    }
  }
}

.ql-container > * {
  background-color: transparent !important;
}

.images-wrapper {
  // display: flex;
  gap: 10px;

  .extra-images {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    }

    .ant-image {
      height: 100px;
      width: 100px;

      .ant-image-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.images-wrapper {
  img {
    border: 1px solid #d6d9e5;
    border-radius: 4px;
  }
}

.product-edit-wrapper {
  width: 100%;
  align-items: center;
}

#edit-product-form {
  .variants-table-wrapper {
    margin-top: 25px;
  }
}

.first_last_name {
  margin-right: 8px;
  font-size: 16px;
  font-weight: 600;
}

.description_wrapper,
.category-col {
  margin-top: 20px;

  .ant-form-item-label {
    margin-bottom: 48px;
    margin-top: 12px;
  }
}
.danger {
  border: 1px solid red;
  border-radius: 4px;
}
.danger-message {
  color: red;
  margin-left: 15px;
  margin-top: 5px;
}

.product-filter {
  .ant-form-inline .ant-form-item {
    margin-right: 4px;
  }
  .selectClass img {
    width: 10px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    font-weight: 600;
  }

  .ant-form-item-control-input-content button {
    height: 32px;
    font-size: 14px;
    line-height: 0;
    background-color: #14305c;
    border: none;
    color: #ffffff;
    margin-left: -1px;
    font-family: 'montserratmedium';
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    &.disabledBtn {
      background-color: #14305c33;
      border: 1px solid #a1a1a1;
    }
  }

  .form-search-filters {
    .ant-form-item-control-input {
      width: 260px;
      margin-bottom: 24px;
    }
    .ant-input-affix-wrapper {
      height: 36px;
      border: 1px solid #ececec;
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
      background: #fafbfc;
    }
    #search_text {
      background: #fafbfc;
    }

    .ant-input-search-button {
      height: 36px;
      width: 40px;
      background-color: #e2e6eb;
      border: 1px solid #ececec;
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }

    .ant-space-horizontal {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
  }
}

.edit__product-local {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-right: 20px;
  .save {
    display: flex;
  }
}

.export__template {
  margin-right: 14px;
}

.import-design {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
}

table:hover,
tr:hover,
thead:hover {
  background: #fff;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none;
}
.seller_product_attributes {
  margin-top: 60px;
}

.quill.input-disabled {
  height: 300px;
}

.long-desc-pricing {
  .ql-container.ql-snow {
    height: 300px;
  }
}

.create-local-product-form {
  .create-product-desc-text {
    background: #f0f2f5;
    padding: 4px 10px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 4px;
    margin-bottom: 0;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
  .product-content {
    background-color: #fff;
    padding: 4px 10px;
    border-radius: 4px;
  }
  .category__wrapper .ant-collapse-content-box,
  .category__wrapper .ant-tree-list-holder {
    background-color: #fff !important;
  }
  .ant-collapse.ant-collapse-icon-position-end {
    background-color: #fff !important;
  }

  .desc-text-mr {
    margin-top: 20px;
  }
  h2.title-text {
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .choose-category {
    display: flex;
    align-items: center;
    color: black;
  }
}

.create-product-desc-text {
  background: #f0f2f5;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  margin-bottom: 0;
  display: flex;
  // gap: 5px;
  align-items: center;
}

.tooltip-info {
  background-color: #a3a3a3;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  margin-left: 6px;
}
.ant-tooltip-inner {
  background-color: #a3a3a3;
}
.ant-tooltip-arrow-content::before {
  background: #a3a3a3;
}

.desc-text-mr {
  margin-top: 20px;
}

.input-variants {
  width: 200px;
}

.input-discount-price {
  width: 200px;
}

.product-content-shopware-attribute {
  background-color: #fff;
  position: relative;
  .attribute_view_product {
    background-color: #fff;
  }
  .attributes_values .ant-tree {
    background-color: #fff !important;
  }
  span.tooltip-info.more-info {
    position: absolute;
    left: 175px;
    top: 25px;
  }
}
.prices-changes {
  text-align: end;
}
.variant__edit {
  margin: 20px 0;
}

.pricing .variat__filters .ant-select-selector {
  .ant-select-selection-placeholder {
    font-weight: normal;
  }
}

.read__variant-last input {
  padding: 0;
}

.data__wrapper-variants {
  margin-bottom: 12px;
  .underline {
    margin-top: 20px;
    margin-bottom: 15px;
    height: 1px;
    background-color: rgba(219, 212, 212, 0.85);
    width: 100%;
  }
}
