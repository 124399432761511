.export__button {
  margin-bottom: 20px;
}

.tree__select-wrapper {
  .ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
    color: black !important;
  }
}

.tree__select {
  .ant-select-selector {
    background-color: transparent !important;
    background: transparent;
    text-align: left;
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    color: #262626;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 11px;
  }
}

.is_cover-image {
  position: relative;

  .ant-image:first-child {
    width: 100%;
  }
  .cover {
    display: none;
  }

  .cover-image {
    position: absolute;
    top: 10px;
    left: 20px;
    color: #000000;
    background: #f0f5ff;
    border-color: #adc6ff;
    padding: 6px 10px;
    border-radius: 8px;
  }
}

.pricing-cover-image.cover-image {
  top: 0;
  left: 0;
  padding: 2px 5px;
  font-size: 13px;
}

.is_cover-image:hover {
  .cover {
    position: absolute;
    top: 0;
    right: 0px;
    z-index: 1;
    display: inline-block;
    color: white;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.all-product-filter {
  .ant-form {
    display: flex;
    flex-direction: column;
  }
  .first__filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 17px;
    .category__map {
      display: flex;
      .ant-btn[disabled],
      .ant-btn[disabled]:hover,
      .ant-btn[disabled]:focus,
      .ant-btn[disabled]:active {
        width: 100%;
      }
      .category__select {
        height: 32px;
      }
      .ant-select-selection-placeholder,
      .unassign {
        letter-spacing: 0px;
        color: #000000;
        font-size: 14px;
      }
      .category__select {
        border: 1px solid #ececec;
        border-radius: 4px 0px 0px 4px;
        background: #fafbfc;
        margin-right: 0;
        label {
          letter-spacing: 0px;
          color: #000000;
          font-size: 14px;
        }
      }

      .map {
        border-radius: 0px 4px 4px 0px;
        background: #52dc9514;
        width: 102px;
        border: none;
        margin-right: 16px;
      }
    }

    h2 {
      letter-spacing: 0px;
      color: #000000de;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 0;
    }

    .ant-select-arrow {
      img {
        width: 8px;
      }
    }

    .ant-select-selector {
      border: 1px solid #ececec;
      border-radius: 4px 0px 0px 4px;
      background: #fafbfc;
      padding-left: 10px;
    }
  }

  .second__filter {
    display: flex;
    label {
      letter-spacing: 0px;
      color: #000000;
      font-size: 14px;
    }
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      letter-spacing: 0px;
      color: #000000;
      font-size: 14px;
      font-weight: normal;
    }
    .ant-select-selector {
      padding-left: 0;
    }
    .input__search {
      input#search_text {
        height: 100%;
      }
    }
  }
}

.text__dots {
  width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.all__prorduct-table {
  .ant-tag-success {
    font-size: 13px;
    cursor: auto;
    border: 1px solid #52dc95;
    border-radius: 4px;
    background: rgba(82, 220, 149, 0.1215686275);
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 32px;
    height: 24px;
    color: #52dc95;
    text-decoration: none;
  }
  .ant-tag-success:last-child {
    margin-right: 0;
  }
}

.table__cursor {
  cursor: pointer;
}

.success-category-red {
  font-size: 14px;
  cursor: initial;
  border: 1px solid #ff9a9d;
  border-radius: 4px;
  background: #fe161d1f;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 32px;
  height: 24px;
  color: #52dc95;
  text-decoration: none;
  svg {
    color: #f76a6e;
  }
}

.New {
  margin: 0 auto;
  letter-spacing: 0px;
  color: #101a50;
  font-size: 13px;
  font-weight: 600;
  border-radius: 15px;
  background: rgba(16, 26, 80, 0.0784313725);
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
}

.In-Shop {
  letter-spacing: 0px;
  color: #800080;
  font-size: 14px;
  border-radius: 15px;
  display: inline-block;
  padding: 6px 12px;
  font-weight: 600;
  width: fit-content;
  background: #e6e6fa;
  text-transform: capitalize;
}

.Approved {
  margin: 0 auto;
  height: 30px;
  width: 90px;
  letter-spacing: 0px;
  color: #1ea576;
  border-radius: 15px;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dcf8ea;
}

.Google-Translate {
  margin: 0 auto;
  height: 30px;
  border-radius: 15px;
  width: 142px;
  letter-spacing: 0px;
  color: #2a7dd1;
  background: #2a7dd114;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order_status {
  font-size: 14px;
  font-weight: 600;
  border-radius: 15px;
  width: auto;
  text-transform: capitalize;
  padding: 2px 16px;
  display: flex;
  background: #ffba0014;
  justify-content: center;
  align-items: center;
  color: #ffba00;
}

.Pending {
  margin: 0 auto;
  height: 30px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 15px;
  width: fit-content;
  text-transform: capitalize;
  padding: 2px 16px;
  display: flex;
  background: #ffba0014;
  justify-content: center;
  align-items: center;
  letter-spacing: 0px;
  color: #ffba00;
}

.additional__filter-attributes {
  letter-spacing: 0px;
  opacity: 1;
  color: #212529;
  font-weight: 500;
  font-size: 14px;
  margin-right: 20px;
}

.filter__attribute-title {
  display: flex;
  border-radius: 4px;
  background-color: #f0f2f5;
  gap: 100px;
  padding: 4px 4px 4px 8px;
  p {
    margin-bottom: 0;
    color: #212529;
    font-size: 14px;
  }
}

.filter__mappingattribute-title {
  display: flex;
  border-radius: 4px;
  background-color: #f0f2f5;
  gap: 100px;
  padding: 4px 4px 4px 8px;
  p {
    margin-bottom: 0;
    color: #212529;
    font-size: 14px;
  }
}

.select__attributes {
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent;
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #212529;
  }
  color: #212529;
}

.mapped__attributes {
  height: 700px;
  overflow-y: auto;
}

.attribute_bg__color{
  background: #d9d9d9 !important;
}

.tag__mappedattributes.ant-tag {
  background: #f5f5f5;
  border: none;
  font-weight: 500;
  font-size: 14px;
  padding: 3px 9px;
}

.attributes__group-title
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: transparent;
  background: transparent;
  border: none;
}

.attributes__group-title {
  margin-bottom: 16px;

  .tooltip-btn {
    visibility: hidden;
    transition: ease-in-out;
    transition-duration: 100ms;
  }

  &:hover {
    .tooltip-btn {
      visibility: visible;
    }
  }

  .tooltip-btn-hidden {
    visibility: hidden;
  }
}

.Archived {
  margin: 0 auto;
  height: 30px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 15px;
  display: flex;
  width: 90px;
  letter-spacing: 0px;
  justify-content: center;
  align-items: center;
  color: #bcbfce;
  background: #f3f5f7;
}
.Removed-from-shop {
  margin: 0 auto;
  color: #856fac;
  height: 30px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 15px;
  display: flex;
  width: 150px;
  letter-spacing: 0px;
  justify-content: center;
  align-items: center;
  background: #856fac14;
}
.In-Shop {
  margin: 0 auto;
  color: #856fac;
  height: 30px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 15px;
  display: flex;
  width: fit-content;
  letter-spacing: 0px;
  justify-content: center;
  align-items: center;
  background: #856fac14;
}

.success-category:hover {
  color: #52dc95;
}
.not-success-approve:hover {
  color: #878eaa;
}

.local-seller-icons {
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: flex-end;
}

.success-category {
  font-size: 13px;
  cursor: initial;
  border: 1px solid #52dc95;
  border-radius: 4px;
  background: #52dc951f;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 32px;
  height: 24px;
  color: #52dc95;
  text-decoration: none;
  svg {
    color: #52dc95;
  }
}

.not-success {
  cursor: initial;
  font-size: 14px;
  border: 1px solid #d6d9e5;
  border-radius: 4px;
  background: #f5f6fa;
  width: 32px;
  height: 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-decoration: none;
  color: #d6d9e5;

  svg {
    color: #878eaa;
  }
}

.not-success-approve {
  cursor: initial;
  font-size: 14px;
  border: 1px solid #d6d9e5;
  border-radius: 4px;
  background: #f5f6fa;
  width: 32px;
  height: 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-decoration: none;
  color: #878eaa;

  svg {
    color: #878eaa;
  }
}

.parent-not-clickable .ant-select-tree-node-content-wrapper,
.prevent__clicking {
  pointer-events: none;
}

.export__button.export__btn {
  justify-content: flex-end;
  margin-right: 16px;
}

.export__button.local__export__btn {
  display: flex;
  justify-content: flex-end;
}

.mapped__attributes
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: white;
}

.product-count-loading {
  background: linear-gradient(to right, #f0f0f0, #d0d0d0);
  height: 20px;
  width: 100px;
  text-align: right;
  margin-left: auto;
  margin-right: 0;
}