.company {
  .action-item {
    background-color: #f5f6fa;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
    border-radius: 2px;
    height: 32px;
    font-size: 14px;
  }
}
.select-multiple-attributes {
  .ant-select-selector {
    border: none !important;
  }
}
