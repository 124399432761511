.ant-modal-wrap .category_modal {
  .ant-modal-header {
    border: none;
  }

  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background: #14305c;
    border-color: #14305c;
  }

  .ant-modal-body {
    background-color: #fafbfc;
  }

  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    background-color: #14305c;
    height: 2px;
  }

  .search-input {
    width: 100%;

    input {
      border: 1px solid #ececec;
      border-radius: 4px;
    }
  }

  .ant-tree {
    background-color: #fafbfc;
  }

  .ant-form-item-control {
    margin-bottom: 24px;
  }

  .ant-modal-footer {
    border-top: none;
  }

  .ant-modal-footer {
    button:first-child {
      color: #14305c;
      font-weight: 600;
      font-size: 16px;
      border: none;
      box-shadow: none;
    }

    button:last-child {
      background: #52dc95;
      color: #101a50;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      border: none;
      width: 100px;
      padding: 0;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.categories-listing-table {
  table th.ant-table-cell {
    font-size: 11px;
    font-weight: 700;
    line-height: 20px;
  }
  .ant-table-tbody > tr > td {
    font-size: 11px;
  }
}

.categories_container {
  background-color: #fff;
  height: 100%;
  padding: 0 24px;

  h1 {
    margin: 0 0 20px 0;
    color: #000000de;
    font-size: 24px;
    font-weight: 600;
    padding-top: 27px;
  }
  .title_categories {
    padding-top: 10px;
  }
  .shopware-filter {
    display: flex;
    justify-content: space-between;

    input {
      background-color: #fafbfc;
      border: 1px solid #ececec;
      border-radius: 4px;
      font-size: 16px;

      ::placeholder {
        font-size: 16px;
        color: #0000005e;
      }
    }
    .categories_btn {
      display: flex;
      gap: 10px;
    }

    .categories_csv {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e6e7ed;
      color: #101a50;
      font-weight: 600;
      height: 36px;
      border-radius: 4px;
      a {
        text-decoration: none;
      }
    }

    .go-to-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #52dc95;
      color: #101a50;
      font-size: 14px;
      line-height: 19px;
      padding: 7px 30px 7px;
      height: 36px;
      width: 185px;
      border-radius: 4px;
      border: none;
      font-weight: 600;

      a {
        text-decoration: none;
        font-size: 13px;
      }
    }
  }

  .categories_wrapper {
    display: flex;
    height: 88%;

    .shopware_table {
      flex: 1;

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #15305c;
        border-color: #15305c;
      }

      .ant-checkbox-indeterminate .ant-checkbox-inner::after {
        background: #fff;
        height: 2px;
      }

      .ant-checkbox-indeterminate .ant-checkbox-inner {
        background: #15305c;
      }

      .ant-table-thead > tr > th {
        padding: 7px 16px;
        background: #f5f6fa;
      }

      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(
          .ant-table-row-expand-icon-cell
        ):not([colspan])::before {
        display: none;
      }

      .ant-table-filter-column {
        justify-content: flex-start;

        .ant-table-column-title {
          flex: none;
        }
      }
    }

    .shopware-categories {
      width: 25%;
      height: 100%;
      background-color: #f5f6fa;
      border-radius: 8px;
      padding: 16px;
      margin-right: 24px;

      h2 {
        color: #1a374d;
        font-weight: bold;
        font-size: 18px;
      }

      .ant-tree {
        background-color: #f5f6fa;
      }
    }
  }
}
