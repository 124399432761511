.login-container {
  display: flex;
  flex-direction: row;
  // height: 100vh;
  // background-position: bottom;
  // background-size: cover;
  // background-image: url('/assets/images/login.jpg');
  // @media only screen and (max-width: 768px) {
  //   display: none;
  // }

  &.forgot-password,
  &.reset-password {
    .content .ant-card.card .ant-form-item-control-input-content .submit-button {
      margin-top: 6px;
    }

    .ant-form-item-with-help {
      margin-bottom: 24px;
    }
  }

  .login-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 670px;
    margin: 10px 20px;

    @media only screen and (max-width: 768px) {
      height: 600px;
    }
  }
  .forgot-pasword-header {
    display: flex;
    flex-direction: column;
  }
  .back-to-login {
    a {
      color: #1ea576;
      font-size: 14px;
      font-family: $font-family;
      font-weight: 500;
      text-decoration: none;
      padding: 10px 0;
      display: inline-block;

      img {
        margin-right: 10px;
        height: 12px;
        vertical-align: middle;
        color: #1ea576;
      }
    }
  }

  .content {
    width: 40%;
    margin: 0 30px;
    // height: 100%;

    @media (max-width: 1024px) {
      width: 60%;
    }

    @media (max-width: 780px) {
      width: 100%;
      height: 100%;
    }

    .ant-card.card {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      @media (max-width: 780px) {
        min-height: 100vh;
      }
      .ant-card-body {
        min-width: 500px;
        padding: 0;
        @media (max-width: 780px) {
          padding: 30px;
        }

        @media (max-width: 1500px) {
          min-width: 400px;
        }
      }

      .logo {
        text-align: center;

        img {
          object-fit: contain;
          margin-bottom: 22px;
          margin-left: 12%;
        }
      }

      .ant-form-item.item {
        margin-bottom: 26px;

        &::before {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 8px;
          font-family: $font-family;
          font-weight: 500;
        }
        .ant-form-item-label
          > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
          display: inline-block;
          margin-right: 4px;
          color: #ff4d4f;
          font-size: 14px;
          font-family: SimSun, sans-serif;
          line-height: 1;
          content: '*';
        }
        .ant-form-item-label
          > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
          display: none;
        }

        // &.email::before {
        //   content: 'Email address';
        // }

        // &.password::before {
        //   content: 'Password';
        // }
      }

      .title {
        font-size: 24px;
        line-height: 27px;
        text-align: left;
        font-family: $font-family;
        font-weight: 600;
        margin-bottom: 30px;
      }

      .ant-form-item-control-input-content > input,
      .ant-input-password {
        border-radius: 5px;
        padding: 14px 18px 13px;
      }

      .ant-form-item-control-input-content input {
        font-size: 14px;
        line-height: 20px;
      }

      .ant-form-item-control-input-content {
        .login-btn {
          display: flex;
          justify-content: flex-end;
          .submit-button {
            width: fit-content;
            height: 46px;
            background-color: #52dc95;
            border-color: #52dc95;
            font-family: $font-family;
            color: #101a50;
            font-weight: 500;
            font-size: 14px;
            line-height: 12px;

            border-radius: 4px;
            margin-top: 30px;
          }
        }
      }

      .ant-form-item.forgot-password-container {
        margin-top: 0;
        margin-bottom: 0;

        .ant-form-item-control-input-content {
          justify-content: space-between;
          display: flex;
          .link {
            text-decoration: none;
            .login-form-forgot {
              text-decoration: none;
              color: $main-color;
              font-size: 14px;
              line-height: 20px;
              font-family: $font-family;
              font-weight: 700;
              @media only screen and (max-width: 768px) {
                font-size: 12px;
              }
            }
          }
        }

        .ant-checkbox-wrapper.checkbox {
          span {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            font-family: $font-family;
            @media only screen and (max-width: 768px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .login-footer {
    background: #e6e7ed;
    min-height: 60px;
    height: auto;
    border-radius: 4px;
    @media only screen and (max-width: 768px) {
      height: 88px;
    }
    .login-footer-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 6px 15px;
      gap: 20px;
      @media only screen and (max-width: 768px) {
        gap: 20px;
      }
      .login-text {
        font-weight: bold;
        font-size: 15px;
        margin-bottom: 2px;
        @media only screen and (max-width: 768px) {
          font-size: 11px;
        }
      }
      p {
        margin-bottom: 0;
        @media only screen and (max-width: 768px) {
          font-size: 11px;
        }
      }
      .sign-up-button {
        background-color: $main-color;
        width: 175px;
        height: 40px;
        border-radius: 4px;
        color: #52dc95;
        @media only screen and (max-width: 768px) {
          width: 130px;
        }
      }
    }
  }
  .footer-login {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .login-forgot-password {
    .login-form-forgot {
      font-size: 14px;
      font-weight: 500;
    }
    .link {
      text-decoration: none;
      color: #1ea576;
    }
  }
}

.login-photo {
  height: 100vh;
  background-position: bottom;
  background-size: cover;
  background-image: url('/assets/images/login.jpg');
  width: 60%;
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.login-form-log {
  color: #1ea576;
}
