.permissions {
  margin: 30px;
  &-checkbox {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #ffc400dc;
    }
    .ant-checkbox-checked .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #ffc400dc;
    }
    .ant-checkbox-inner .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner,
    .ant-checkbox-inner {
      border-color: #ffc400dc;
    }
  }

  &-space {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: fit-content;
    background-color: white;
    margin: 6px 0;
    padding: 8px 10px;
    border: 1px solid white;
    border-radius: 5px;
    font-size: 13px;

    .ant-typography {
      margin: 0;
      text-transform: capitalize;
    }

    > .ant-space-item:first-child {
      z-index: 9999;
      position: sticky;
      top: 0;
      left: 0;
      &::after {
        content: '';
        background: white;
        position: absolute;
        width: 100px;
        left: 0;
        top: 0;
        right: 10px;
        bottom: 0;
        z-index: -1;
      }
    }

    &-list {
      display: 'flex';
      justify-content: 'flex-end';
      align-items: center;
      flex-flow: row;
    }
  }
  &-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    margin: 6px 0;
    padding: 2px 10px;

    .ant-typography {
      margin: 0;
    }
    > .ant-space-item:first-child {
      z-index: 9999;
      position: sticky;
      top: 0;
      left: 0;

      &::after {
        content: '';
        background-color: #f5f6fa;
        position: absolute;
        width: 100px;
        left: 0;
        top: 0;
        right: 0;
        bottom: -30px;
        z-index: -1;
      }
    }

    &-list {
      display: 'flex';
      justify-content: 'flex-end';
      align-items: flex-start;
      flex-flow: column;
    }
  }

  &-collapse {
    padding: 3px;
    margin-bottom: 40px;
    border-radius: 4px;
    .ant-collapse-header {
      background-color: #f5f6fa;
    }
    .ant-collapse-content-box {
      background-color: #f5f6fa;
    }
  }

  &-text {
    font-size: 13px;
  }
  &-save-btn {
    background-color: #52dc95;
    height: 36px;
    border-radius: 4px;
    font-weight: 600;
    width: 97px;
  }
  .ant-collapse-header-text {
    font-weight: 600;
    font-size: 16px;
  }

  &-selected {
    width: 98px;
  }

  .rolelistform {
    overflow-x: auto;
  }

  &-title {
    width: 720px;
  }
  &-title-content {
    width: 615px;
  }
  &-title-table {
    // width: 615px;
    width: 100px;
    z-index: 99999;
    background-color: #ffff;
  }

  &-edit-role-btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  &-form {
    margin: 20px 0;
    overflow-x: auto;
  }
  &-check-status,
  &-check-status-x {
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
  }

  &-check-status {
    background-color: #7ddf6429;
  }
  &-check-status-x {
    background-color: #ef233c29;
  }
}
