.pricing {
  height: 100%;
  background-color: white;
  padding: 24px 36px;

  &__container {
    display: flex;
    justify-content: space-between;
  }

  h1 {
    letter-spacing: 0px;
    color: #000000de;
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 15px 0;
  }

  .ant-select-selector .ant-select-selection-placeholder {
    font-weight: normal;
    font-size: 14px;
    color: #000000;
  }

  &__filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    flex-direction: column;
    gap: 16px;

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding-left: 2px;
    }

    label {
      letter-spacing: 0px;
      color: #000000;
      font-size: 14px;
      &::after {
        margin: 0;
      }
    }
  }

  &__export-input {
    display: flex;
    gap: 16px;
    height: 36px;
  }

  &__discount {
    .ant-form-item-control {
      margin-left: -2px;
    }

    .tax__price-select {
      .ant-select-selector {
        border: 1px solid #ececec;
        border-left: none;
        background: #fafbfc;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    .ant-select-selection-item {
      letter-spacing: 0px;
      color: #000000;
      font-size: 14px;
      font-weight: 600;
    }

    .ant-input-number-input {
      width: 93px;
    }

    .ant-input-number-lg {
      margin-left: 8px;
      border: 1px solid #ececec;

      input::placeholder {
        color: #0000005c;
        font-size: 14px;
      }
    }

    .ant-input-number:not(:first-child):not(:last-child) {
      border-radius: 4px;
    }

    .ant-form-item-label {
      border: 1px solid #ececec;
      height: 40px;
      background: #fafbfc;
      border-right: none;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      label {
        height: 100%;
        letter-spacing: 0px;
        color: #000000;
        font-size: 14px;
        padding-left: 12px;
      }
    }

    .ant-input-number-group-addon {
      background: #fafbfc;
      border: 1px solid #ececec;
      border-left: none;
      background: #fafbfc;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;

      &:last-child {
        background-color: #ececec;
        border-radius: 4px;
      }
    }
  }
  &__wrapper {
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;
    flex-direction: column;
    gap: 16px;

    &-form {
      display: flex;
      button {
        border-radius: 4px;
        background: #52dc95;
        border: none;
        letter-spacing: 0px;
        color: #101a50;
        font-size: 14px;
        font-weight: bold;
        height: 40px;
        padding: 0 32px;
        margin-left: 8px;
      }
      .ant-row {
        align-items: center;
      }

      .ant-form-item {
        margin-bottom: 0;
      }
    }

    button {
      border-radius: 4px;
      background: #e6e7ed;
      border: none;
      padding: 0 16px 0 12px;
      color: #14305c;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }

  &__currency {
    display: flex;
    height: 40px;

    &-content {
      border: 1px solid #ececec;
      border-radius: 4px;
      background: #fafbfc;
      padding: 0 12px;
      letter-spacing: 0px;
      color: #000000;
      font-size: 14px;
      display: flex;
      align-items: center;
      span {
        font-weight: bold;
        margin-left: 24px;
      }
    }

    button {
      border-radius: 4px;
      background: #e6e7ed;
      border: none;
      letter-spacing: 0px;
      color: #101a50;
      font-size: 14px;
      font-weight: bold;
      margin-left: 8px;
    }
  }

  .ant-form-item {
    height: 100%;
  }

  .ant-form-item-control-input {
    margin-bottom: 0;
  }

  .ant-select-arrow {
    img {
      width: 10px;
    }
  }
  thead {
    .ant-table-cell {
      background: #f5f6fa;
      padding: 7px 7px;
      letter-spacing: 0px;
      color: #252525;
      font-size: 13px;
      font-weight: bold;
      &::before {
        display: none;
      }
    }
  }

  tbody .ant-table-cell p {
    margin: 0;
  }

  &__pagination {
    text-align: right;
    margin-top: 15px;
  }

  .set__opacity {
    opacity: 0.7;
  }
}
.pricing__filter {
  .form-search-filters {
    .ant-form-item-control-input {
      margin-bottom: 0;
    }
  }
}
.variant__drawer.product__description {
  margin: 0 0 30px 0;
}

.variant__drawer {
  margin-bottom: 12px;
  h2 {
    letter-spacing: 0px;
    font-size: 14px;
    line-height: 19px;
    color: #212529;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
  }
  p {
    margin-bottom: 0;
    font-weight: normal;
  }

  .variant_images_container {
    display: flex;
    gap: 10px;
  }
}

// .edit-wrapper {
//   margin-bottom: 20px;
// }

.pricing__clear-btn {
  border-radius: 4px;
  background: transparent;
  border: none;
  letter-spacing: 0px;
  box-shadow: none;
  color: #1ea576;
  font-size: 14px;
  font-weight: bold;
  padding: 6px 0 0;
  cursor: pointer;
  height: 34px;
}
.price__dashboard {
  .export_csv-active {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .export_csv {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 7px 15px !important;
    span {
      padding-bottom: 0px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      // bottom: 4px;
      // left: 6px;
    }
  }
}
.pricing-search {
  .ant-form-item-control-input {
    margin-bottom: 0 !important;
  }
}

.table__scroll {
  overflow-x: auto;
}

.currency__wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.import_table {
  td.ant-table-cell.long-description {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
  }
}

.status-pricing {
  white-space: nowrap;
}
