/// Vertical rhythm baseline
/// @type Length
$vertical-rhythm-baseline: 1.5rem;

/// Regular font stack
/// @type List
$font-family: 'Inter', sans-serif;

/// Code font stack
/// @type List
$code-font-stack: 'Source Code Pro', 'Courier New', monospace;

/// Light grey
/// @type Color
$light-grey: rgb(238, 238, 238);

/// Grey
///@type Color
$grey: rgb(245, 246, 250);

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68);

/// Light pink
/// @type Color
$light-pink: rgb(204, 102, 153);

/// Dark pink
/// @type Color
$dark-pink: rgb(179, 115, 153);

/// Dark purple
/// @type Color
$dark-purple: rgb(100, 70, 130);

/// Light purple
/// @type Color
$light-purple: rgb(125, 90, 160);

/// purple
/// @type Color
$purple: rgb(155, 110, 200);

/// Light yellow
/// @type Color
$light-yellow: rgb(242, 236, 228);

/// Light green
/// @type Color
$light-green: rgb(210, 225, 221);

/// White
/// @type Color
$light-white: rgb(255, 255, 255);

/// Dark Blue
/// @type Color
$dark-blue: rgb(20, 48, 92);

/// Header text color
/// @type Color
/// @require $light-white
$header-color: $light-white;

/// Main color
/// @type Color
/// @require $dark-blue
$main-color: $dark-blue;

/// Secondary color
/// @type Color
/// @require $dark-pink
$secondary-color: $dark-pink;

/// Note background
/// @type Color
/// @require $light-grey
$note-background: $light-grey;

/// Quote background
/// @type Color
/// @require $light-green
$quote-background: $light-green;

/// Code background
/// @type Color
/// @require $dark-grey
$code-background: $dark-grey;

/// Donate background
/// @type Color
/// @require $light-yellow
$donate-background: $light-yellow;

/// Figure background
/// @type Color
/// @require $light-yellow
$figure-background: $light-grey;

/// ToC width
/// @type Length
$sidebar-width: 300px;

/// Main container width
/// @type Length
$main-width: 975px;

/// Container width
/// @type Length
$content-width: 650px;

/// Breakpoints
/// @type Map
$breakpoints: (
  'small': 600px,
  'medium': 801px,
  'large': $main-width,
);
