/**
 * Header
 */
.albania-header {
  background: red !important;
}
.slovenia-header {
  background: yellow !important;
}
.header-layout {
  background: #f5f6fa;
  height: 48px;
  line-height: 35px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .welcome__user {
    p {
      margin-bottom: 0;
      letter-spacing: 0px;
      color: #000000;
      font-size: 12px;
      line-height: 17px;
    }

    span {
      color: #000000;
      font-weight: 500;
    }
  }

  .user_header {
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
    .image__user-header {
      background: #dbdbdb;
      padding: 6px 6px;
      border-radius: 50%;
      margin-right: 0;

      img {
        margin-right: 0;
      }
    }
    img {
      height: 20px;
    }
  }
  span {
    letter-spacing: 0.23px;
    color: #000000;
    font-size: 14px;
    margin-right: 24px;
    line-height: 1;
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: $font-family;
    font-weight: 400;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    img {
      margin-right: 12px;
      height: 24px;
      width: auto;
      object-fit: contain;
    }
  }

  .svg-icons {
    margin-right: 12px;
  }
}

.header {
  background: $main-color;
  border-bottom: 0.5em solid darken($secondary-color, 5%);
  color: rgb(255, 255, 255);
  overflow: hidden;
  padding: 1.5rem 0;
  text-align: center;
}

/**
 * Main title
 */
.title {
  font-size: 6em;
  margin: 0;
  max-width: none;

  @include media('<small') {
    font-size: 3em;
  }
}

/**
 * Site Baseline
 */
.baseline {
  @include container;
  color: $main-color;
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 3rem;
  margin-top: 3rem;
  text-transform: initial;

  @include media('>medium') {
    font-size: 2.5em;
  }

  @include media('>large') {
    max-width: $main-width;
  }
}
