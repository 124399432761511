.metric-users {
  &-select {
    flex-flow: row;
  }
  &-title {
    font-size: 12px;
  }

  &-statistic {
    .ant-card {
      border-radius: 8px;
      background: #f5f6fa;
    }

    h3,
    p {
      color: #000000;
    }

    h3 {
      font-size: 18px;
      line-height: 21px;
    }
    p {
      font-size: 30px;
      line-height: 34px;
      font-weight: 600;
      margin: 10px 0 0 0;
    }
  }
}
