.edit-role-container {
  label.ant-checkbox-wrapper.ant-checkbox-group-item {
    width: calc(50% - 15px);
    margin-bottom: 8px;

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $dark-purple;
      border-color: $dark-purple;
    }
  }

  .ant-checkbox-checked::after,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $dark-purple;
  }
}
