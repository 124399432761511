.category__wrapper {
  .category__active {
    display: flex;
    gap: 12px;
    align-items: center;

    h5 {
      letter-spacing: 0px;
      font-size: 14px;
      line-height: 19px;
      color: #212529;
      font-family: 'Inter', sans-serif;
      font-weight: 700;
      margin-bottom: 0;
    }

    &-checkbox {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .tree-local-product-attributes {
    span.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  .ant-collapse-header {
    background-color: white;
  }

  .ant-collapse-content-box,
  .ant-tree-list-holder {
    background: #fafbfc;
  }

  .ant-collapse,
  .ant-collapse-item,
  .ant-collapse-content {
    border: none;
  }

  .ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
    border-radius: 8px;
    padding: 8px 16px;
  }

  .ant-collapse-header {
    border: 1px solid #ececec;
  }

  .ant-collapse-header-text {
    font-size: 14px;
    color: #262626;
    font-weight: 600;
  }

  .ant-select-selector {
    background: white !important;
    border: 1px solid #ececec !important;
  }

  &-select {
    padding: 8px 0 40px 10px;
    background-color: white;

    .ant-select {
      width: 86%;
    }

    .ant-select-selector {
      background: white !important;
      border: 1px solid #ececec !important;
      padding-top: 8px;
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      padding-left: 10px !important;
    }
  }

  .ant-table-tbody>tr>td {
    border-bottom: 0;
    padding-bottom: 4px;
    padding-top: 8px;
  }

  .ant-table-thead>tr>th {
    padding-bottom: 10px;
    padding-top: 10px;
  }
}

.hide-icon {
  .ant-select-selection-item-remove {
    display: none;
  }
}

.select__view-product .ant-select-selector {
  height: auto !important;
}