.local__serive {
  height: 100%;
  background-color: white;
  padding: 24px 24px;
  h1 {
    letter-spacing: 0px;
    color: #000000de;
    margin: 0 0 16px 0;
    font-size: 24px;
    font-weight: 600;
  }
  .local__wrapper {
    display: flex;
    gap: 24px;
    margin-bottom: 24px;
    .local__box {
      background: #fafbfc;
      border-radius: 8px;
      padding: 24px 12px;
      height: 20%;
      min-height: 234px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h2 {
        letter-spacing: 0px;
        color: #393939;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 12px;
      }
      p {
        letter-spacing: 0px;
        color: #393939;
        font-size: 14px;
        margin-bottom: 24px;
      }
    }

    .info,
    .content {
      p {
        margin-bottom: 10px;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    .content {
      p {
        font-weight: 600;
      }
    }

    .local__serive-info {
      display: flex;
      justify-content: space-between;
      .content {
        text-align: right;
      }
    }
  }

  .local__wrapper:last-child {
    margin-bottom: 0;
  }

  .serivce__table {
    background: #fafbfc;
    border-radius: 8px;
    padding: 24px 16px 16px;
    margin-bottom: 24px;

    h2 {
      letter-spacing: 0px;
      color: #393939;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 12px;
    }
    .ant-table-thead {
      .ant-table-cell {
        background: #f5f6fa;
        padding: 6px 16px;
        color: #252525;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        padding: 10px 16px;
        border-bottom: 1px solid #f5f6fa;
        letter-spacing: 0px;
        color: #262626;
      }
    }
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan])::before {
      display: none;
    }

    .download {
      letter-spacing: 0px;
      color: #1ea576;
      font-size: 14px;
      cursor: pointer;
      border: none;
      padding: 0;
      box-shadow: none;
      height: auto;
      text-decoration: none;
    }
  }

  .service__faq {
    background: #fafbfc;
    border-radius: 8px;
    padding: 24px 16px 16px;
    margin-bottom: 24px;

    .bold {
      font-weight: 600;
    }

    .ant-collapse-content-box {
      width: 90%;
      color: #000000;
    }

    .collapse__title {
      display: flex;
      gap: 12px;
      align-items: center;
      p {
        letter-spacing: 0px;
        color: #000000;
        margin-bottom: 0;
        font-weight: 600;
        font-size: 14px;
      }
    }

    h2 {
      letter-spacing: 0px;
      color: #393939;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 12px;
    }

    .ant-collapse-item {
      margin-bottom: 12px;
      box-shadow: 0px 7px 14px #101a5015;
      border-radius: 4px;
      background: white;
    }

    .ant-collapse-borderless > .ant-collapse-item {
      border-bottom: 0;
    }
  }
}

span.ant-select-arrow {
  img {
    width: 10px;
  }
}
