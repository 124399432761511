@import '../abstracts/variables';

.categorie-wrapper {
  .ant-table-row .ant-table-cell:last-child,
  .ant-table-thead .ant-table-cell:last-child {
    text-align: left;
  }
}

.dashboard-container {
  padding: 24px;
  background-color: white;
  height: 100%;

  &.dashboard-roles-container {
    .dashboard-header {
      margin-bottom: 24px;
    }
  }

  .language-image {
    display: flex;
    align-items: center;

    img {
      width: 50px;
    }

    h4 {
      margin: 0;
      margin-left: 20px;
    }
  }

  // // Checkbox -- Start
  // .ant-checkbox-wrapper {
  //   &:hover .ant-checkbox-inner,
  //   .ant-checkbox-input:focus + .ant-checkbox-inner {
  //     border-color: $main-color;
  //   }

  //   .ant-checkbox-checked {
  //     &::after {
  //       border-color: $main-color;
  //     }

  //     .ant-checkbox-inner {
  //       background-color: $main-color;
  //       border-color: $main-color;
  //     }
  //   }

  //   .ant-checkbox-indeterminate .ant-checkbox-inner {
  //     background-color: $main-color;

  //     &::after {
  //       height: 2px;
  //       background-color: #fff;
  //     }
  //   }
  // }

  // Checkbox -- End

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: rgba($color: $main-color, $alpha: 0.08);
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: $main-color;
    box-shadow: 0 0 0 2px rgba($color: $main-color, $alpha: 0.2);
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: $main-color;
  }

  // Pagination -- Start
  .ant-pagination {
    .ant-pagination-prev,
    .ant-pagination-next {
      &:focus-visible,
      &:hover {
        .ant-pagination-item-link {
          border-color: $main-color;
          color: $main-color;
        }
      }
    }

    .ant-pagination-item {
      &:focus-visible,
      &:hover {
        border-color: $main-color;
      }

      a,
      &:focus-visible a,
      &:hover a {
        color: $main-color;
        text-decoration: none;
      }

      &-active {
        border-color: $main-color;

        a,
        &:focus-visible a,
        &:hover a {
          border-color: $main-color;
          color: $main-color;
          text-decoration: none;
        }
      }
    }
  }

  // Pagination -- End

  .form-user-filters.ant-form-inline .ant-form-item {
    margin-bottom: 24px;
  }

  &.product-dashboard {
    table {
      td.ant-table-cell {
        padding: 5px 14px;
      }
    }

    .ant-pagination {
      text-align: right;
      margin-top: 15px;
    }
  }

  table {
    th.ant-table-cell {
      background-color: #f5f6fa;
      border: none;
      font-size: 11px;
      line-height: 20px;
      text-transform: capitalize;
      padding: 5px 15px;
      // padding: 14px 14px 13px;
      font-family: $font-family;
      font-weight: 700;

      // &:nth-child(5),
      // &:nth-child(9),
      // &:nth-child(10) {
      //   text-align: right;
      // }

      &:nth-child(5) {
        text-align: left;
      }

      &::before {
        display: none;
      }
    }

    > thead > {
      tr:first-child th:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      tr:first-child th:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    .ant-table-cell {
      font-size: 11px;
      line-height: 20px;
      color: #262626;
      padding: 10px 14px 10px;
      font-family: $font-family;

      p {
        margin: 0;
      }

      &:last-child {
        text-align: right;
      }

      .product-image {
        height: 45px;
      }

      .action-item {
        background-color: #f5f6fa;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 7px;
        height: 24px;
        font-size: 14px;
        border: 1px solid #d6d9e5;
        border-radius: 4px;
        width: 32px;
        font-size: 14px;
        border: 1px solid #d6d9e5;
      }

      .action-view {
        display: flex;
        justify-content: end;
      }
    }
  }
}

.action-item {
  background-color: #f5f6fa;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  height: 24px;
  font-size: 14px;
  border: 1px solid #d6d9e5;
  border-radius: 4px;
  width: 32px;
  font-size: 14px;
  border: 1px solid #d6d9e5;
  .action-item-delete {
    height: 13px;
  }
}

.text-right {
  text-align: right;
}

.space-nowrap {
  white-space: nowrap;
}

main.site-layout-background {
  padding: 16px;
  background-color: white;

  .ant-statistic {
    margin-bottom: 20px;
  }
}

.green_status {
  color: #1ea576;
  background-color: #dcf8ea;
  text-transform: capitalize;
  padding: 2px 16px;
  height: 22px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  margin: 0 !important;
}

.red_status {
  color: #fe161d;
  background-color: rgba(254, 22, 29, 0.0784313725);
  text-transform: capitalize;
  padding: 2px 16px;
  height: 22px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  margin: 0 !important;
}

.yellow_status {
  color: #ffba00;
  background-color: #ffba0014;
  text-transform: capitalize;
  padding: 2px 16px;
  height: 22px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  margin: 0 !important;
}

.price__logs {
  .price__filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  h1 {
    letter-spacing: 0px;
    color: #000000de;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
  }

  .button {
    letter-spacing: 0px;
    color: #101a50;
    font-size: 14px;
    font-weight: bold;
    border-radius: 4px;
    border: none;
    padding: 8px 12px;
    background: #52dc95;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    margin: 0;
  }
}

.product-dashboard .ant-space-item .order-select-component .ant-select-selector {
  width: 317px;
  border: 1px solid #0000005e;
  border-radius: 4px;
  border-color: #0000005e;
}

.order-input {
  width: 67px;
}

.order-title {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  h2 {
    font-weight: 600;
  }
}

.order-image {
  width: 190px;
  height: 160px;
}

.count_language {
  color: #878eaa;
  font-weight: 600;
  font-size: 14px;
  border: 1px solid #d6d9e5;
  border-radius: 4px;
  padding: 3px 10px;
  background-color: #f5f6fa;
  cursor: pointer;
  padding: 2px;
  margin: 2px;
}

.langauge_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .ant-tag-default {
    display: flex;
    width: 32px;
    height: 24px;
    justify-content: center;
    align-items: center;
    border: 1px solid #d6d9e5;
    border-radius: 4px;
    background-color: #f5f6fa;
    color: #878eaa;
    font-weight: 600;
    padding: 2px 4px;
  }
  .ant-tag-default:last-child {
    margin-right: 0;
  }
}

.translated_languages_popover {
  .ant-tag-default {
    border: 1px solid #d6d9e5;
    border-radius: 4px;
    background-color: #f5f6fa;
    color: #878eaa;
    font-weight: 600;
    padding: 2px 4px;
  }
  .ant-tag-default:last-child {
    margin-right: 0;
  }
}

.translated_languages_popover {
  .ant-popover-inner {
    max-width: 220px;
    margin: 0 0 0 auto;
    border-radius: 10px;

    .ant-popover-inner-content {
      display: flex;
      flex-wrap: wrap;
    }

    .ant-tag {
      // width: 36px;
      margin-bottom: 5px;
      text-align: center;
      margin: 1px;
      padding: 2px;
    }
  }
}

.username_table {
  background: #14305c8f;
  width: 28px;
  height: 28px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 8px;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  cursor: pointer;
}
.unassigned {
  background-color: #d4dadd;
  color: #14305c8f;
}

.unassign_table {
  background: #d4dadd;
  width: 28px;
  height: 28px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 8px;
  font-weight: 600;
  font-size: 12px;
  color: #14305c8f;
}

.unassign_table_select {
  background: #d4dadd;
  width: 26px;
  height: 26px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 8px;
  font-weight: 600;
  font-size: 12px;
  color: #14305c8f;
}

.unassign {
  color: #2626268f;
}

.username_title {
  color: #262626;
  font-size: 11px;
  font-weight: 600;
}

.status_check {
  border-radius: 4px;
  padding: 4px 10px;
  color: #14305c;
  font-weight: 600;
  font-size: 11px;
  background: #14305c1f;
}

.assgine-flex {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column;

  div {
    margin: 5px 0;
  }
}

.product-wrapper {
  display: flex;
  margin-top: 10px;

  .products {
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
  }

  .translated-products {
    font-weight: 600;
    font-size: 16px;
  }
}

.languages-select {
  display: flex;
  align-items: center;
  width: 140px;
}

.tabs-title {
  font-size: 24px;
  font-weight: 600;
}

.company-pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
}

.companies-page {
  margin-bottom: 20px;
  .ant-btn-primary {
    height: 30px;
  }
}

.local-dashboard-bg {
  background-color: #fafbfc !important;
  .statistic-cards {
    width: 100%;
    margin-bottom: 30px;
  }
  .local-dashboard {
    display: flex;
    gap: 21px;

    .card-content {
      width: 306px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 10px 14px #14305c14;
      border-radius: 8px;
      .ant-statistic {
        position: relative;
      }
      .total-products {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 51px;
        right: 20px;
        .products-status {
          display: flex;
          gap: 10px;
          .dashboard-card-p {
            font-weight: 600;
          }
          .products-active {
            width: 51px;
          }
        }
      }
    }
    .ant-card {
      border-radius: 8px;
    }
    span.ant-statistic-content-value-int {
      font-size: 30px;
      font-weight: 700;
    }
    .ant-statistic-content-value-decimal {
      font-size: 30px;
      font-weight: 700;
    }

    .ant-statistic-content-value {
      font-size: 30px;
      font-weight: 700;
    }
    .ant-statistic-title {
      color: #474b54;
    }
  }
  .local-dashboard-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    .chart {
      box-shadow: 0px 10px 14px #14305c14;
      border-radius: 8px;
      width: 100%;
      background-color: #fff;
      padding: 20px;
    }
    .news {
      box-shadow: 0px 10px 14px #14305c14;
      border-radius: 8px;
      width: 40%;
      background-color: #fff;
      padding: 20px;

      .news-content {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: flex-start;
        a {
          color: #474b54;
          text-decoration: underline;
        }
        a:hover {
          color: #52dc95;
        }
        .skeleton {
          width: 148px;
          height: 93px;
          // background-color: #f5f6fa;
          margin-bottom: 10px;
          img {
            width: 148px;
            height: 93px;
          }
        }
        .tutorial-image {
          display: flex;
          gap: 7px;
          img {
            object-fit: none;
          }
        }
      }
    }
    .news-title {
      font-size: 14px;
      color: #474b54;
      font-weight: 500;
    }
    thead .ant-table-cell {
      background-color: #f5f6fa;
      border: none;
      font-size: 11px;
      line-height: 20px;
      text-transform: inherit;
      padding: 5px 15px;
      font-family: 'Inter', sans-serif;
      font-weight: 700;
    }
    .ant-table-tbody > tr > td {
      font-size: 11px;
    }
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    )::before {
    display: none;
  }
}
