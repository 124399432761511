.profit-wrapper {
  padding: 30px 24px;
  background-color: #ffffff;
  height: 100%;

  h1 {
    color: #000000de;
    font-size: 24px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 24px;
  }

  &-spin {
    margin-bottom: 100px auto;
    padding: 30px 50px;
    text-align: center;
  }

  &-form {
    width: max-content;
    background-color: #fafbfc;
    padding: 24px;

    .ant-form-item {
      margin-bottom: 16px;
    }

    .ant-form-item-control-input {
      width: 280px;
    }

    .ant-select-in-form-item {
      border: 1px solid #ececec;
      border-radius: 4px;
      background-color: #ffffff;
    }

    .ant-input-group {
      display: flex;
      .ant-input {
        border: 1px solid #ececec;
        border-radius: 4px 0 0 4px;
        background-color: #ffffff;
      }
    }

    .ant-input-group-addon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 43px;
      background-color: #ececec;
      border: none;
    }

    img {
      width: 12px;
    }

    // button {
    //   background-color: #14305c;
    //   width: 120px;
    //   border-radius: 4px;
    //   border: none;
    //   font-size: 14px;
    //   font-weight: 600;
    //   color: #ffffff;
    //   height: 40px;
    //   margin-top: 6px;
    // }

    .ant-form-item-required {
      flex-direction: row-reverse;
      font-size: 14px;
      color: #000000;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
}

.send-to-shop-wrapper {
  padding: 30px 24px;
  background-color: #ffffff;
  height: 100%;

  .sync-button {
    letter-spacing: 0px;
    color: #101a50;
    font-size: 14px;
    font-weight: bold;
    border-radius: 4px;
    border: none;
    padding: 8px 12px;
    background: #52dc95;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    white-space: nowrap;
    margin-left: 5%;
    height: 32px
  }

  h1 {
    color: #000000de;
    font-size: 24px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 24px;
  }

  &-spin {
    margin-bottom: 100px auto;
    padding: 30px 50px;
    text-align: center;
  }

  &-form {
    width: max-content;
    background-color: #fafbfc;
    padding: 24px;

    .ant-form-item {
      margin-bottom: 16px;
    }

    .ant-form-item-control-input {
      min-width: 600px;
    }

    .ant-select-in-form-item {
      border: 1px solid #ececec;
      border-radius: 4px;
      background-color: #ffffff;
    }

    .ant-input-group {
      display: flex;
      .ant-input {
        border: 1px solid #ececec;
        border-radius: 4px 0 0 4px;
        background-color: #ffffff;
      }
    }

    .ant-input-group-addon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 43px;
      background-color: #ececec;
      border: none;
    }

    img {
      width: 12px;
    }

    .ant-form-item-required {
      flex-direction: row-reverse;
      font-size: 14px;
      color: #000000;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
}

.advanced-settings-wrapper {
  padding: 30px 24px;
  background-color: #ffffff;
  height: 100%;

  h1 {
    color: #000000de;
    font-size: 24px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 24px;
  }
}

.smtp-email-wrapper {
  padding: 30px 24px;
  background-color: #ffffff;
  height: 100%;

  h1 {
    color: #000000de;
    font-size: 24px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 24px;
  }

  &-spin {
    margin-bottom: 100px auto;
    padding: 30px 50px;
    text-align: center;
  }

  &-form {
    width: max-content;
    background-color: #fafbfc;
    padding: 24px;

    .ant-form-item {
      margin-bottom: 16px;
    }

    .ant-form-item-control-input {
      width: 300px;
    }

    .ant-select-in-form-item {
      border: 1px solid #ececec;
      border-radius: 4px;
      background-color: #ffffff;
    }

    .ant-input-group {
      display: flex;
      .ant-input {
        border: 1px solid #ececec;
        border-radius: 4px 0 0 4px;
        background-color: #ffffff;
      }
    }

    .ant-input-group-addon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 43px;
      background-color: #ececec;
      border: none;
    }

    img {
      width: 12px;
    }

    .ant-form-item-required {
      flex-direction: row-reverse;
      font-size: 14px;
      color: #000000;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
}
