@import '../abstracts/variables';
.form-user-filters {
  .search-input {
    .ant-select.ant-select-in-form-item.ant-select-status-success.ant-select-single.ant-select-show-arrow.ant-select-show-search
      span[aria-live='polite'] {
      // Removes a jumping effect when changing the select item
      display: none !important;
    }

    .ant-form-item-control-input-content {
      display: flex;
      height: 32px;
      width: 260px;

      input {
        border: 1px solid #ececec;
        border-radius: 4px;
        // border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        &::placeholder {
          letter-spacing: 0px;
          color: #0000005e;
          font-size: 14px;
        }
      }

      button {
        height: 32px;
        border-radius: 0px 4px 4px 0px;
        background-color: #e2e6eb;
        border: 1px solid #ececec;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 14px;
        }
      }
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 180px;
    border: 1px solid #ececec;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    height: 32px;
    align-items: center;
  }

  .ant-select-selection-placeholder {
    letter-spacing: 0px;
    color: #0000008a;
    font-size: 14px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search {
    top: 0px;

    @media (max-width: 1500px) {
      top: 0px;
    }
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 42px;

    @media (max-width: 1500px) {
      line-height: 2;
    }
  }
}

.filters .order-select-component {
  width: 317px;
}

.ant-form-inline .select-user-product {
  .assgine_language .ant-select-selector {
    border-radius: 0;
    border-left: 0;
    width: 120px;
  }
  .ant-form-item-label label {
    font-size: 14px;
    height: 100%;
    color: #0000008a;
    margin-right: 16px;
  }
  .ant-form-item-label label::after {
    display: none;
  }
  .ant-form-item-control-input-content {
    display: flex;
  }
  .ant-form-item-control-input-content button {
    height: 32px;
    font-size: 14px;
    line-height: 0;
    background-color: #52dc9514;
    border: none;
    color: black;
    margin-left: -1px;
    font-family: 'montserratmedium';
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    &.disabledBtn {
      background-color: #52dc9514;
      color: #101a503d;
      border: 1px solid #ececec;
    }
  }

  .ant-select-selector {
    input {
      font-size: 14px;
      font-family: 'montserratmedium';
    }
    .ant-select-selection-item {
      font-size: 14px;
      font-family: 'montserratmedium';
      // line-height: 49px;
    }
  }
}

.activate_btn {
  background-color: $main-color;
  color: white;
  border-radius: 4px;
  font-size: 11px;
  width: 80px;
}

.filters {
  .order-select-component {
    width: 317px;
  }

  .save-button {
    background-color: $main-color;
    color: white;
    font-size: 14px;
    line-height: 19px;
    text-transform: capitalize;
    padding: 7px 30px 6px;
    height: 100%;
    border-radius: 4px;
    border: none;
    box-shadow: none;

    &:hover,
    &:focus {
      background-color: $main-color;
      color: white;
      text-decoration: none;
    }
  }

  .product-status .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px;
  }

  .cancel-button {
    border: none;
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0;
    box-shadow: none;
    background-color: white;

    &:hover,
    &:focus {
      border-color: $main-color;
    }

    span {
      color: $main-color;
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      margin: 0 20px;
    }
  }
}

.ant-layout-content .dashboard-container .language_fliter {
  .ant-select-selector {
    border-radius: 4px;
  }
  img {
    width: 10px;
  }
}

.form-user-filters .select-width {
  .ant-select-arrow {
    display: flex;
  }
  img {
    width: 10px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    padding-left: 0;
  }

  .ant-select-selection-item {
    letter-spacing: 0px;
    color: #1a374d;
    font-weight: 600;
  }
}

.brend__info {
  color: black;
  font-size: 14px;
  letter-spacing: 0px;
}

.ant-select-dropdown {
  min-width: 90px !important;
}

.dashboard-container .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: transparent !important;
}

.form-search-filters {
  .ant-form-item-control-input {
    width: 300px;
    margin-bottom: 24px;
  }
  .ant-input-affix-wrapper {
    height: 36px;
    border: 1px solid #ececec;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    background: #fafbfc;
  }
  #search_text {
    background: #fafbfc;
    height: 100%;
  }

  .ant-input-search-button {
    height: 36px;
    width: 40px;
    background-color: #e2e6eb;
    border: 1px solid #ececec;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  .ant-space-horizontal {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

  .ant-select-selection-item {
    letter-spacing: 0px;
    color: #1a374d;
    font-weight: 600;
  }
}
.select_date {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .select_label {
    margin-right: 10px;
  }
}

.form-user-filters .selectClass {
  .ant-select-selector {
    background-color: #fafbfc;
  }
}

.date-picker-filter {
  border-color: #0000005e;
  border-radius: 4px;
  .ant-picker-suffix {
    color: #14305c;
  }
  .ant-picker-range-separator {
    display: none;
  }
  .ant-picker-input > input {
    line-height: normal;
    padding: 3px;
  }
}
.first-date {
  margin-right: 10px;
}

.search-name {
  border-radius: 4px;
  border-right: 1px solid rgba(0, 0, 0, 0.368627451);
  margin-right: 0;
}

.btn-search {
  height: 32px;
  border-radius: 4px;
  background-color: rgb(20, 48, 92);
  border: 1px solid rgb(20, 48, 92);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 14px;
  }
  &:hover {
    background-color: rgb(20, 48, 92);
  }
}

.language_filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .language_btn {
    margin-bottom: 24px;
  }
}

.import-filters {
  .ant-form-item {
    margin-bottom: 0;
  }
}

.update-button-view-product {
  background-color: $main-color;
  color: white;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  padding: 7px 30px 6px;
  height: 100%;
  border-radius: 4px;
  border: none;
  box-shadow: none;

  &:hover,
  &:focus {
    background-color: $main-color;
    color: white;
    text-decoration: none;
  }
}

.local-seller-product-search {
  input#search_text {
    height: 36px;
    border: 1px solid #ececec;
  }
}

button.ant-btn.ant-btn-default.create-company {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #52dc95;
  padding: 8px 10px;
  font-weight: 500;
}

.all-product-filter.all-product-filter-product .second__filter .ant-select-selection-placeholder {
  font-weight: normal;
}

.all-product-filter.all-product-filter-product .second__filter .ant-select-selection-item {
  font-weight: 500;
}

.all-product-filter.all-product-filter-product .ant-select-selection-search {
  left: 0;
}
