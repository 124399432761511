.categorit-required {
  .ant-form-item-label {
    padding-bottom: 0;
  }
}

.input__wrapper input,
.input__wrapper .ant-input-affix-wrapper {
  width: 162px !important;
}

.input__wrapper_number input,
.input__wrapper_number .ant-input-affix-wrapper {
  width: 120px !important;
}

.input__wrapper_quantity input,
.input__wrapper_quantity .ant-input-affix-wrapper {
  width: 68px !important;
}

.color-red {
  color: red;
  margin-right: 3px;
}

.cat-parent-not-clickable {
  .ant-tree-checkbox {
    pointer-events: none;
    cursor: no-drop;
  }
}

.hide-form {
  display: none;
}

.full-width-form .ant-space .ant-space-item {
  flex: 1;
}

.full-width-form .ant-space .ant-space-item:last-child {
  flex: none;
}

.show-form {
  display: none;
}

.switch-local-seller {
  .ant-modal-body {
    padding: 40px 60px 24px 60px;
    background: #fafbfc;
  }

  .ant-modal-footer {
    background: #fafbfc;
    border-top: 0;
    padding: 0 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    button:first-child {
      letter-spacing: 0px;
      color: #101a50;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
      border: none;
      box-shadow: none;
    }

    button:last-child {
      border-radius: 4px;
      background: #52dc95;
      letter-spacing: 0px;
      color: #101a50;
      font-size: 14px;
      font-weight: 600;
      border: none;
    }
  }

  h2 {
    letter-spacing: 0px;
    color: #000000de;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 14px;
    text-align: center;
    line-height: 29px;
  }

  p {
    letter-spacing: 0px;
    color: #000000de;
    font-size: 16px;
    margin-bottom: 0;
    text-align: center;
    line-height: 20px;
  }
}

.switch-local-seller.deactivated {
  .ant-modal-footer {
    flex-direction: row-reverse;

    button:first-child {
      border-radius: 4px;
      background: #52dc95;
      letter-spacing: 0px;
      color: #101a50;
      font-size: 14px;
      font-weight: 600;
      border: none;
    }

    button:last-child {
      letter-spacing: 0px;
      color: #101a50;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
  }
}

.input-with-tags.disabled {
  background-color: rgba(20, 48, 92, 0.0392156863);

  input {
    background-color: transparent !important;
  }
}

.input-with-tags {
  display: flex;
  width: 100%;
  max-width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1607843137);
  border-radius: 5px;
  color: black;
  height: auto;
  min-height: 42px;
  padding-left: 11px;
  background-color: white;
  flex-wrap: wrap;

  .tag {
    display: flex;
    align-items: center;
    margin: 7px 0;
    margin-right: 10px;
    padding: 0 10px;
    padding-right: 5px;
    border: none;
    border-radius: 5px;
    background: #dcf8ea;
    white-space: nowrap;
    color: #1ea576;
    font-size: 14px;
    height: 24px;

    button {
      display: flex;
      padding: 6px;
      border: none;
      background-color: unset;
      cursor: pointer;
      color: white;
      color: black;
    }
  }

  .ant-input {
    width: 100%;
    min-width: 50%;
    border: none !important;
    border-radius: 5px;
    padding: 14px;
    padding-left: 0 !important;
    outline: none;
    border-radius: 4px;
    font-size: 14px;
    min-width: 140px;
    width: 140px;
  }

  .ant-input:focus,
  .ant-input-focused {
    outline: none;
    border-color: white;
    box-shadow: none;
  }
}

.ant-switch-checked {
  background-color: #52dc95;
}

.country__select {
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 100%;
  }
  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
}

.local__seller {
  background-color: white;
  height: 100%;
  padding: 36px 32px;

  .review__pointer {
    cursor: pointer;
  }

  .ant-pagination {
    margin-top: 12px;
    text-align: right;
  }

  .delete-seller {
    cursor: pointer;
  }

  thead .ant-table-cell {
    background-color: #f5f6fa;
    border: none;
    font-size: 11px;
    line-height: 20px;
    text-transform: inherit;
    padding: 5px 15px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
  }
  .ant-table-tbody > tr > td {
    font-size: 11px;
  }

  h2 {
    margin-bottom: 15px;
    letter-spacing: 0px;
    color: #000000de;
    font-size: 24px;
    font-weight: 600;
  }

  .local__filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .local__buttons {
      display: flex;
      align-items: center;
      gap: 16px;

      .export {
        letter-spacing: 0px;
        color: #101a50;
        font-size: 14px;
        font-weight: bold;
        background: #e6e7ed;
        border-radius: 4px;
        border: none;
        padding: 8px 16px;
      }

      .create {
        letter-spacing: 0px;
        color: #101a50;
        font-size: 14px;
        font-weight: bold;
        border-radius: 4px;
        border: none;
        padding: 8px 12px;
        background: #52dc95;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        white-space: nowrap;
      }
    }
  }

  .Hapur {
    letter-spacing: 0px;
    color: #101a50;
    font-size: 14px;
    font-weight: 600;
    padding: 6px 12px;
    background: #e2e6eb;
    border-radius: 15px;
    display: inline-block;
    text-transform: capitalize;
  }

  .Jo-aprovuar {
    letter-spacing: 0px;
    color: #fe161d;
    font-size: 14px;
    font-weight: 600;
    padding: 6px 12px;
    border-radius: 15px;
    display: inline-block;
    background: #fe161d14;
    text-transform: capitalize;
  }

  .Ignored {
    letter-spacing: 0px;
    color: #fe161d;
    font-size: 14px;
    font-weight: 600;
    padding: 6px 12px;
    border-radius: 15px;
    display: inline-block;
    background: #fe161d14;
    text-transform: capitalize;
  }

  .Aprovuar {
    letter-spacing: 0px;
    color: #1ea576;
    font-size: 14px;
    border-radius: 15px;
    display: inline-block;
    padding: 6px 12px;
    font-weight: 600;
    background: #dcf8ea;
    text-transform: capitalize;
  }

  .Në-shop {
    letter-spacing: 0px;
    color: #800080;
    font-size: 14px;
    border-radius: 15px;
    display: inline-block;
    padding: 6px 10px;
    font-weight: 600;
    background: #e6e6fa;
    text-transform: capitalize;
  }

  .Informacione-pritje {
    letter-spacing: 0px;
    font-size: 14px;
    border-radius: 15px;
    display: inline-block;
    padding: 6px 12px;
    font-weight: 600;
    color: #2a7dd1;
    background: #2a7dd114;
    text-transform: capitalize;
  }

  .suspended-row {
    color: red !important;
  }

  .suspended-product {
    background-color: rgb(251 251 202) !important;
  }

  .ant-form-item-control-input {
    margin-bottom: 0;
  }

  .ant-table-tbody > tr > td:last-child {
    text-align: right;
  }

  .ant-table-thead > tr > th:last-child {
    text-align: right;
  }
}

.add__field {
  color: #52dc96;
  text-align: left;
  border: 1px dashed #eef0f4;
  background: transparent;
  box-shadow: none;
  width: 100%;
  cursor: pointer;
}

.add__field:disabled {
  cursor: no-drop;
}

.view__product {
  display: flex;
  gap: 12px;
  justify-content: flex-end;

  .cancel {
    background-color: transparent;
    border: none;
    font-size: 14px;
    color: #15305c;
    box-shadow: none;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
  }

  .update {
    color: #15305c;
    background-color: #f5f7f9;
    border: none;
    font-size: 14px;
    cursor: pointer;
    box-shadow: none;
    border-radius: 8px;
    font-weight: 600;
  }

  .reject {
    color: #fe373d;
    background-color: #ffeded;
    border: none;
    font-size: 14px;
    box-shadow: none;
    cursor: pointer;
    font-weight: 600;
    border-radius: 8px;
    height: 36px;
  }

  .reject:disabled {
    cursor: not-allowed;
  }

  .save {
    display: flex;
    color: #21a678;
    background-color: #f1fcf7;
    font-size: 14px;
    box-shadow: none;
    font-weight: 600;
    border-radius: 8px;
    border: none;
    height: 36px;
    align-items: center;
  }
}

.local__drawer .ant-drawer-body .sku-prefix-seller {
  input {
    background-color: white;
  }
}

.local__drawer .ant-drawer-body .seller-datepicker {
  .ant-picker.ant-picker-disabled {
    background-color: rgba(20, 48, 92, 0.0392156863);
  }

  #registration_date {
    width: 100%;

    .ant-picker {
      width: 100%;
    }
  }

  input {
    border: none;
    background-color: transparent;
    font-size: 14px;
  }
}

.local__drawer {
  .brand-create {
    .brendi {
      margin-bottom: 0;

      span {
        color: green;
        cursor: pointer;
      }
    }
  }

  .edit-added-x,
  .edit-added-wrapper {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    flex-direction: row-reverse;
    margin-right: 10px;
    justify-content: space-between;

    .edit-wrapper-title {
      display: inline-flex;
    }
    p {
      margin-bottom: 0;
      cursor: pointer;
    }
  }

  .ql-container.ql-snow {
    min-height: 100px;
  }

  .ant-drawer-close {
    display: none;
  }

  .form__list {
    display: flex;
    gap: 168px;

    p {
      margin-bottom: 0;
    }
  }

  .image-text {
    padding-top: 22px;
    padding-bottom: 22px;
    padding-right: 10px;
    margin-bottom: -38px;
    background: #f9fbfc;
    z-index: 2;
    display: inline-block;
  }

  .images__product {
    display: flex;
    margin-bottom: 24px;
    gap: 12px;
    flex-wrap: wrap;
    border: 1px dashed rgba(0, 0, 0, 0.1607843137);
    padding: 20px;
    margin-top: -10px;
  }

  .category-auto {
    .ant-select-selector {
      height: auto !important;
    }
  }

  .category__shopware {
    margin-bottom: 20px;
  }

  .ant-switch-checked {
    background-color: #52dc96;
  }

  .create-brand {
    text-align: left;
    letter-spacing: 0px;
    font-size: 14px;
    color: black;

    span {
      color: #1ea576;
      font-weight: 600;
      cursor: pointer;
    }
  }

  .ant-drawer-body {
    background: #f9fbfc;

    .ant-form-item-label {
      label {
        color: black;
        font-size: 14px;
      }
    }
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: rgba(20, 48, 92, 0.0392156863);
  }

  .ant-drawer-body {
    input,
    .ant-select-selector {
      background-color: rgba(20, 48, 92, 0.0392156863);
      border: 1px solid #00000029;
      border-radius: 4px;
      height: 40px;
    }

    .ql-editor.ql-blank,
    .ql-editor {
      background-color: rgba(20, 48, 92, 0.0392156863);
    }

    .ant-select-selection-placeholder {
      font-size: 14px;
    }

    h2 {
      letter-spacing: 0px;
      color: #000000;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 14px;
    }

    .ant-form-item-label label {
      letter-spacing: 0px;
    }
  }

  .date {
    margin-bottom: 20px;
    margin-top: 16px;
  }
}

.clock-seller {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;
  // margin-right: 20px;
}

.local__drawer.input__on {
  input,
  .ant-select-selector,
  .ql-editor.ql-blank,
  .ql-editor {
    background-color: white;
  }
}

.switch__product {
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
}

.treeDatacategory .ant-tree-treenode-disabled .ant-tree-title {
  color: black;
}

.update__modal {
  .ant-modal-footer {
    display: none;
  }

  .ant-modal-content {
    border-radius: 8px;

    .ant-modal-body {
      background: #fafbfc;
      border-radius: 8px;

      .ant-form-item-label {
        padding-bottom: 8px;

        label {
          letter-spacing: 0px;
          color: black;
          font-size: 14px;
          font-weight: normal;
          line-height: 17px;
          height: auto;
        }
      }

      h2 {
        text-transform: inherit;
        letter-spacing: 0px;
        color: #000000de;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 14px;
        line-height: 29px;
      }

      p {
        letter-spacing: 0px;
        color: #000000de;
        font-size: 16px;
        text-align: center;
        margin-bottom: 24px;
        font-weight: normal;
        line-height: 20px;
      }

      input,
      textarea {
        border: 1px solid #ececec;
        border-radius: 4px;
        background: #ffffff;
        min-height: 46px;
      }

      input::placeholder,
      textarea::placeholder {
        letter-spacing: 0px;
        color: #0000005c;
        font-size: 14px;
      }

      textarea {
        min-height: 141px;
      }
    }

    .ant-modal-footer {
      background: #fafbfc;
      border: none;
      text-align: center;
      padding: 0 0 24px 0;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      .ant-btn-default {
        letter-spacing: 0px;
        color: #101a50;
        font-size: 14px;
        font-weight: 600;
        border: none;
        background-color: transparent;
        box-shadow: none;
      }

      .ant-btn-primary {
        border-radius: 4px;
        background: #52dc95;
        letter-spacing: 0px;
        color: #101a50;
        font-size: 14px;
        font-weight: 600;
        border: none;
        padding: 0 24px;
        height: 36px;
      }
    }
  }
}

.review__sellers {
  cursor: pointer;
}

.update__modal.reject__modal {
  .ant-modal-footer {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;

    .ant-btn-default {
      border-radius: 4px;
      background: #52dc95;
      letter-spacing: 0px;
      color: #101a50;
      font-size: 14px;
      font-weight: 600;
      border: none;
      padding: 0 24px;
      height: 36px;
    }

    .ant-btn-primary {
      letter-spacing: 0px;
      color: #101a50;
      font-size: 14px;
      font-weight: 600;
      border: none;
      background-color: transparent;
      box-shadow: none;
    }
  }
}

.seller-cancel-button {
  letter-spacing: 0px;
  color: #14305c;
  font-size: 14px;
  font-weight: 600;
  border: none;
  background-color: transparent;
  box-shadow: none;
  margin-right: 10px;
}

.seller-save-button {
  background: #52dc95;
  border-radius: 4px;
  height: 36px;
  letter-spacing: 0px;
  color: #101a50;
  border: none;
  font-size: 14px;
  font-weight: 600;
  padding: 0 28px;
}

.seller-save-button:hover {
  background: #52dc95;
  color: white;
  transition: all 0.4s ease-in-out;
}

.modal__update {
  text-align: center;
  margin-top: 40px;
}

.validate_images_size {
  color: red;
}

.upload-list-images-product {
  .ant-upload-list.ant-upload-list-picture-card {
    display: flex;
    flex-direction: initial;
    width: 100%;

    .ant-upload.ant-upload-select.ant-upload-select-picture-card {
      display: block;
      margin-bottom: 0px;
    }
    .ant-upload.ant-upload-select-picture-card {
      width: 98px;
      height: 98px;
    }
  }

  .ant-upload-list-picture .ant-upload-list-item,
  .ant-upload-list-picture-card .ant-upload-list-item {
    padding: 2px !important;
  }
  .ant-image {
    :hover {
      cursor: pointer;
    }
  }
}

.is__cover-image {
  display: flex;
  flex-direction: column-reverse;
}

.is__cover-image .set-cover__image {
  display: none;
}

.cover__image {
  position: absolute;
  top: 3px;
  color: #000000;
  background: #f0f5ff;
  border-color: #adc6ff;
  padding: 1px 4px;
  border-radius: 8px;
  font-size: 12px;
}

.is__cover-image:hover .set-cover__image {
  position: absolute;
  top: 0;
  z-index: 1;
  font-size: 12px;
  display: inline-block;
  color: white;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
}

.upload-list-images-variants {
  .ant-upload-list-picture-card-container {
    height: 98px;
    margin-bottom: 0px;
    width: auto;
    min-width: 80px;
    vertical-align: bottom;
  }

  .ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture-card {
    padding: 2px !important;
  }

  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    height: 98px;
    margin-bottom: 0px;
    width: 100px;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 98px;
    height: 98px;
  }
  .ant-image {
    :hover {
      cursor: pointer;
    }

    height: 27px;
  }
}

.upload-list-images {
  .ant-modal-body {
    padding: 0px !important;
  }
}

.bg-icon {
  background-color: #f5f6fa;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  height: 24px;
  font-size: 14px;
  border: 1px solid #d6d9e5;
  border-radius: 4px;
  width: 32px;
  font-size: 14px;
  border: 1px solid #d6d9e5;
}

.variant__checkbox,
.create__attribute {
  color: green;
  cursor: pointer;
  margin-left: 3px;
}

.local-product-filter {
  input#search_text {
    height: 36px;
  }
}

.category__modal {
  margin-left: 3px;
  span {
    color: green;
    cursor: pointer;
  }
}

.checkbox-variant {
  display: flex;

  .variant-modal {
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    margin-left: -4px;
  }
}

.comma-error {
  color: #ff4d4f;
  font-size: 14px;
  margin-bottom: 0;
}

.product-content {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .ant-input-affix-wrapper {
    height: 40px;
    background-color: transparent;
    border-radius: 4px;
    input {
      border: none;
      height: 100%;
    }
  }
}

.product-variants {
  .ant-input-affix-wrapper {
    height: 40px;
    background-color: transparent;
    border-radius: 4px;
    input {
      border: none;
      height: 100%;
    }
  }

  background-color: white;
  background-image: /* Shadows */ linear-gradient(to right, white 0%, white 50%, 100%),
    linear-gradient(to left, white 0%, white 50%, rgba(255, 255, 255, 0) 100%),
    /* Shadow covers */
      linear-gradient(
        to right,
        rgba(0, 0, 0, 0.1) 0%,
        rgba(255, 255, 255, 0) 50%,
        rgba(255, 255, 255, 0) 100%
      ),
    linear-gradient(
      to left,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  background-repeat: no-repeat;

  background-position: left center, right center, left center, right center;
  background-size: 10px 100%, 10px 100%, 10px 100%, 10px 100%;

  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
  -webkit-overflow-scrolling: touch;

  /* 
    Progressive enhancement of scrollbar on webkit
    Use media queries to only use on mobile */
  &::-webkit-scrollbar {
    height: 6px;
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

.create-local-product-content {
  .edit-wrapper-title-product {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .wrapper-x {
    margin-bottom: 0;
    cursor: pointer;
  }
}
.edit-wrapper-x {
  background: #14305c0a;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px !important;
}

.ant-pagination-item a {
  text-decoration: none;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #d7d7d7;
  cursor: not-allowed;
  background-color: rgba(20, 48, 92, 0.0392156863);
}
.ant-select-lg {
  font-size: 14px;
}

.ant-picker-input > input[disabled] {
  color: black;
  cursor: default;
}

.readOnly {
  background-color: rgba(20, 48, 92, 0.0392156863) !important;
  input {
    background-color: transparent !important;
  }
}

.set__cover-local {
  letter-spacing: 0px;
  color: #14305c;
  font-size: 16px;
  font-weight: 600;
  border: none;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
}

.disabled_order_request {
  pointer-events: auto;
  cursor: not-allowed;
}

.spinner_to-shop {
  background: rgba(0, 0, 0, 0.27);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(0.4px);
  z-index: 9999;
}

.return__center {
  text-align: center;
}

.approve__button-text {
  background: rgba(82, 220, 149, 0.0784313725);
  border-radius: 4px;
  letter-spacing: 0px;
  color: #1ea576;
  font-size: 14px;
  width: 109px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  border: none;
}

.reject__button-text {
  background: rgba(254, 22, 29, 0.0784313725);
  border-radius: 4px;
  letter-spacing: 0px;
  color: #fe161d;
  font-size: 14px;
  width: 104px;
  height: 35px;
  border: none;
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  justify-content: center;
}

.to__text {
  padding-top: 20px;
  display: inline-block;
}

.euro__usd-input {
  .ant-form-item-control-input {
    width: 588px;

    .ant-input-number {
      width: 100%;
    }
  }
  .ant-input-number-wrapper {
    width: 588px;
    input::placeholder {
      font-size: 13px;
      font-weight: 300;
    }
  }
  input::placeholder {
    font-size: 14px;
    font-weight: 300;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-input-number-handler-wrap {
  display: none;
}

.edit__product-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  .edit-wrapper-x {
    margin-bottom: 0 !important;
    margin-right: 20px;
  }
}

.seller-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .export_csv {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 7px 15px !important;
    background-color: #e6e7ed;
    color: #101a50;
    span {
      padding-bottom: 0px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      // bottom: 4px;
      // left: 6px;
    }
  }
}
